import { createSlice } from '@reduxjs/toolkit';

export const booksSlice = createSlice({
	name: 'books',
	initialState: [],
	reducers: {
		getAllbooks: (state, action) => {
			return action.payload;
		},
		updateClass: (state, action) => {
			return state.map((item, index) => {
				return action.payload.id === item.id
					? {
							...action.payload,
					  }
					: item;
			});
		},
		addBook: (state, action) => {
			return [...state, action.payload];
		},
		deleteBook: (state, action) => {
			return state.filter((element) => element.id !== action.payload);
		},
		logoutBook: (state, action) => {
			return [];
		},
	},
});

export const { getAllbooks, logoutBook, updateClass, addBook, deleteBook } =
	booksSlice.actions;
export default booksSlice.reducer;
