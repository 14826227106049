import React from "react";
import "./modal.css";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addSubType } from "../../redux/subtypeSlice";
import instance from "../../apiInstance";
export default function Subscription({
  isOpen,
  setIsOpen,
  success,
  setSuccess,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("subscription_name", data.subscription_name);
    formdata.append("subscription_description", data.description);
    formdata.append("price_per_month", data.price);
    formdata.append("fullpass", data.fullpass);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/sub-types`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        dispatch(addSubType(res.data.sub_type));
        setIsOpen(false);
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto "
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <span className="close" onClick={() => setIsOpen(false)}>
        &times;
      </span>
      <div className="modal-content mx-auto mt-32 border-2 rounded p-4">
        <h3 className="text-gray-500 text-3xl mt-4 text-center">
          Add new Subscription
        </h3>
        <form
          className=" w-64 block mx-auto py-2 mt-4 mb-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label className="text-center text-xl text-gray-800">
            Subscription Name
          </label>
          {errors.subscription_name && (
            <p className="text-red-600">{errors.subscription_name.message}</p>
          )}
          <input
            type="text"
            {...register("subscription_name", {
              required: "Subscription name is required",
            })}
            placeholder="Subscription name"
            style={{
              color: "#5f6061",
              border: errors.subscription_name
                ? "2px solid red"
                : "2px solid gray",
            }}
            className="w-[250px] my-2 p-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">
            Subscription Description
          </label>
          {errors.description && (
            <p className="text-red-600">{errors.description.message}</p>
          )}
          <textarea
            type="text"
            {...register("description", {
              required: "Subscription Description is required",
            })}
            placeholder="Subscription Description"
            style={{
              color: "#5f6061",
              border: errors.description ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">Price</label>
          {errors.price && (
            <p className="text-red-600">{errors.price.message}</p>
          )}
          <input
            type="number"
            {...register("price", {
              required: "Price is required",
            })}
            placeholder="Price"
            style={{
              color: "#5f6061",
              border: errors.price ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">FullPass</label>
          {errors.fullpass && (
            <p className="text-red-600">{errors.fullpass.message}</p>
          )}
          <select
            {...register("fullpass")}
            style={{
              color: "#5f6061",
              border: errors.price ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          >
            <option value="1" selected>
              True
            </option>
            <option value="0">False</option>
          </select>
          <input
            type="submit"
            value="Create"
            className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-red-400 mt-8 transition-all duration-200 text-white font-bold"
          />
        </form>
      </div>
    </div>
  );
}
