import React from "react";
import "./modal.css";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { addSubType } from "../../redux/subtypeSlice";
import { addUser } from "../../redux/usersSlice";
import instance from "../../apiInstance";
import { addBook } from "../../redux/booksSlice";
export default function DropIn({
  isOpen,
  setIsOpen,
  success,
  setSuccess,
  setError,
  setMessage,
  cl,
  date,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    formdata.append("password_confirmation", data.password2);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/user/register`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success

        dispatch(addUser(res.data.user));
        const formData = new FormData();
        formData.append("users_id", res.data.user.id);
        formData.append("date", convertDate(date));
        formData.append("class_time_id", cl);

        instance({
          url: "/admin/book",
          data: formData,
          method: "POST",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success

            dispatch(addBook(res.data.book));
            setSuccess(true);
            setIsOpen(false);
          })
          .catch((e) => {
            // handle error
            console.log(e);
          });
      })
      .catch((e) => {
        // handle error
        setMessage(e.response.data.message);
        setError(true);
        setIsOpen(false);
      });
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto "
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <div className="modal-content mx-auto lg:w-[500px] w-full mt-32 border-2 rounded p-4">
        <span
          className="lg:mt-0 mt-6 text-black cursor-pointer flex justify-end text-4xl font-extrabold"
          onClick={() => setIsOpen(false)}
        >
          &times;
        </span>
        <h3 className="text-gray-500 text-3xl mt-4 text-center">
          Add new User
        </h3>

        <form
          className=" w-64 block mx-auto py-2 mt-4 mb-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label className="text-center text-xl text-gray-800">
            User Fullname
          </label>
          {errors.name && <p className="text-red-600">{errors.name.message}</p>}
          <input
            type="text"
            {...register("name", {
              required: "Name is required",
            })}
            placeholder="User Fullname"
            style={{
              color: "#5f6061",
              border: errors.name ? "2px solid red" : "2px solid gray",
            }}
            className="w-[250px] my-2 p-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">Email</label>
          {errors.email && (
            <p className="text-red-600">{errors.email.message}</p>
          )}
          <input
            type="text"
            {...register("email", {
              required: "Email is required",
            })}
            placeholder="Email"
            style={{
              color: "#5f6061",
              border: errors.email ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">Password</label>
          {errors.password && (
            <p className="text-red-600">{errors.password.message}</p>
          )}
          <input
            type="password"
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            placeholder="Password"
            style={{
              color: "#5f6061",
              border: errors.password ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">
            Confirm Password
          </label>
          {errors.password2 && (
            <p className="text-red-600">{errors.password2.message}</p>
          )}
          <input
            type="password"
            {...register("password2", {
              required: "Confirmation Password is required",
              validate: (val) => {
                if (watch("password") !== val) {
                  return "Your passwords do not match";
                }
              },
            })}
            placeholder="Confirm Password"
            style={{
              color: "#5f6061",
              border: errors.password2 ? "1px solid red" : "1px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <input
            type="submit"
            value="Create"
            className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-red-400 mt-8 transition-all duration-200 text-white font-bold"
          />
        </form>
      </div>
    </div>
  );
}
