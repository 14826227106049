import {
  Alert,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { GiCancel } from "react-icons/gi";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubType, updateSubType } from "../../redux/subtypeSlice";
import Danger from "../modal/danger";
import { deleteClass, updateClass } from "../../redux/classesSlice";
import { SketchPicker } from "react-color";
import "./style.css";
import instance from "../../apiInstance";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SingleCourse({ course }) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [color2, setColor] = useState("#000");
  const user = useSelector((state) => state.user);
  const subtypes = useSelector((state) => state.subtypes);
  const [sub, setSub] = useState([]);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setValue("name", course.name);
    setValue("description", course.description);
    setValue("subscription_type_id", course.subscription_type_id);
    setColor(course.color);
    if (course.subscription_type) {
      for (let i = 0; i < course.subscription_type.length; i++) {
        setSub((sub) => [
          ...sub,
          course.subscription_type[i].subscription_name,
        ]);
      }
    }
  }, []);
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("description", data.description);
    formdata.append("color", color2);
    let str = "";
    for (let i = 0; i < sub.length; i++) {
      console.log(sub[i]);
      let elem = subtypes.filter((s) => s.subscription_name === sub[i]);
      str += elem[0].id + ",";
    }

    formdata.append("subscription_type_id", str.slice(0, -1));
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/classes/${course.id}/update`,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
      data: formdata,
    })
      .then((res) => {
        // handle success
        dispatch(updateClass(res.data.class));
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  const deleteSubscription = () => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/classes/${course.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(deleteClass(course.id));
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSub(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <div
      className="border-2 border-gray-300 h-full w-full text-center pb-12 pt-4 px-2 text-xl rounded-lg"
      style={{ backgroundColor: course.color }}
    >
      <div className="flex justify-end">
        <GiCancel
          className="text-red-500 cursor-pointer"
          size={25}
          onClick={() => setOpen(true)}
        />
      </div>
      <form
        className=" w-64 block mx-auto py-2 mt-4 mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {errors.name && <p className="text-red-600">{errors.name.message}</p>}
        <label className="text-white">Name</label>
        <input
          type="text"
          {...register("name", {
            required: "Name is required",
          })}
          placeholder="Course name"
          style={{
            color: "white",
            border: errors.name ? "2px solid red" : "2px solid gray",
          }}
          className="w-[250px] my-2 p-2 rounded "
        />
        {errors.description && (
          <p className="text-red-600">{errors.description.message}</p>
        )}
        <label className="text-white">Description</label>
        <textarea
          type="text"
          {...register("description", {
            required: "Subscription Description is required",
          })}
          placeholder="Subscription Description"
          style={{
            color: "white",
            border: errors.description ? "2px solid red" : "2px solid gray",
          }}
          className="p-2 w-[250px] my-2 rounded  text-base"
        />

        {errors.subscription && (
          <p className="text-red-600">{errors.subscription.message}</p>
        )}
        <label className="text-white">Subscription Type</label>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={sub}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          className="border-2   block w-full text-white"
        >
          {subtypes.map((sub2) => (
            <MenuItem key={sub2.id} value={sub2.subscription_name} className="">
              <div className="bg-black">
                <Checkbox
                  checked={sub.indexOf(sub2.subscription_name) > -1}
                  sx={{
                    color: "pink",
                    "&.Mui-checked": {
                      color: "red",
                    },
                  }}
                  className="bg-black"
                />
              </div>
              <ListItemText primary={sub2.subscription_name} />
            </MenuItem>
          ))}
        </Select>
        {/* <select
          defaultValue={sub}
          value={sub}
          onChange={(e) => setSub(e.target.value)}
          style={{
            color: "white",
            border: errors.price ? "2px solid red" : "2px solid gray",
          }}
          className="p-2 w-[250px] my-2 rounded bg-gray-700"
        >
          {subtypes.map((sub) => {
            return <option value={sub.id}>{sub.subscription_name}</option>;
          })}
        </select> */}
        <SketchPicker
          color={color2}
          onChange={(color) => setColor(color.hex)}
          className="mx-auto block my-2"
        />
        <input
          type="submit"
          value="Save"
          className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-green-400 mt-8 transition-all duration-200 text-white font-bold"
        />
      </form>
      <Danger
        isOpen={open}
        setIsOpen={setOpen}
        text={`Are you sure you want to delete subscription`}
        action={deleteSubscription}
      />
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Class Updated
        </Alert>
      </Snackbar>
    </div>
  );
}
