import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import { useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { addWod } from "../../redux/wodSlice";
import { addpost } from "../../redux/postSlice";
import instance from "../../apiInstance";
export default function CreateWod({ type }) {
  const user = useSelector((state) => state.user);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [title, setTitle] = React.useState("");
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const submitPost = () => {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("title", title);
    formData.append(
      "body",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    formData.append("date", formatDate(date));
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/posts`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        setSuccess(true);
        const editorState2 = EditorState.push(
          editorState,
          ContentState.createFromText("")
        );
        setEditorState(editorState2);
        setTitle("");
        if (type === "wod") {
          dispatch(addWod(res.data.post));
        } else {
          dispatch(addpost(res.data.post));
        }
      })
      .catch((e) => {
        // handle error
      });
  };
  return (
    <div>
      <div className="flex flex-col mb-6">
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          value={date}
          selected={date}
          startDate={date}
          onChange={(update) => {
            setDate(update);
          }}
          withPortal
          className=" cursor-pointer flex justify-center items-center text-center mx-auto rounded-lg"
        />
        <label>Title</label>
        <input
          type="text"
          value={title}
          className=" rounded-lg p-2"
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className=" editor rounded-xl" style={{ minHeight: "400px" }}>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      <div className="flex justify-end items-end">
        <button className="bg-green-600 mr-4 mt-6" onClick={submitPost}>
          Submit
        </button>
      </div>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
