import React, { useState, useEffect } from "react";
import { BsPeopleFill } from "react-icons/bs";
import { AiOutlineDelete, AiOutlineSave } from "react-icons/ai";
import Students from "../modal/students";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { deleteUser, updateUser } from "../../redux/usersSlice";
import { Alert, Snackbar } from "@mui/material";
import instance from "../../apiInstance";
export default function UserSingle({ user }) {
  const [success, setSuccess] = useState(false);
  const subtypes = useSelector((state) => state.subtypes);
  const token = useSelector((state) => state.user.token);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [roles, setRoles] = useState(user.roles);
  const [sub, setSub] = useState(user.subscription);
  const [type, setType] = useState(user.user_sub);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setRoles(user.roles);
    setSub(user.subscription);
    if (user.user_sub) {
      setType(user.user_sub.subscription_type);
    }
  }, [user]);
  const handleUpdate = () => {
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("roles", roles);
    formdata.append("subscription", sub);
    formdata.append("user_sub", type);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/user/${user.id}/update`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => {
        // handle success
        dispatch(updateUser(res.data.user));
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
      });
  };
  const handleDelete = () => {
    instance({
      url: `/admin/user/${user.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => {
        // handle success
        dispatch(deleteUser(user.id));
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
      });
  };
  return (
    <tr className="tableRow text-end">
      <td className="text-center px-6">
        <input value={name} onChange={(e) => setName(e.target.value)} />
      </td>
      <td className="text-center py-3 px-6">
        <input value={email} onChange={(e) => setEmail(e.target.value)} />
      </td>
      <td className="text-center py-3 px-6">
        <select onChange={(e) => setRoles(e.target.value)} value={roles}>
          <option value="admin">Admin</option>
          <option value="user">User</option>
          <option value="trial">Trial</option>
        </select>
      </td>
      <td className="text-center py-3 px-6">
        <input value={sub} onChange={(e) => setSub(e.target.value)} />
      </td>
      <td className="text-center py-3 px-6">
        <select onChange={(e) => setType(e.target.value)}>
          <option value={null}></option>
          {subtypes.map((sub) => {
            if (user.user_sub) {
              if (user.user_sub.sub_type) {
                if (sub.id === user.user_sub.sub_type.id)
                  return (
                    <option value={sub.id} selected="selected">
                      {sub.subscription_name}
                    </option>
                  );
              }
            }
            return <option value={sub.id}>{sub.subscription_name}</option>;
          })}
        </select>
      </td>
      <td className="text-center">
        <AiOutlineSave
          size={25}
          className="inline-block mx-2 cursor-pointer hamburger"
          onClick={handleUpdate}
        />
        <AiOutlineDelete
          size={25}
          className="inline-block mx-2 cursor-pointer  text-red-500"
          onClick={handleDelete}
        />
      </td>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          {user.name} successful action!
        </Alert>
      </Snackbar>{" "}
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      {/* <Students isOpen={open} setIsOpen={setOpen} lesson={lesson} /> */}
    </tr>
  );
}
