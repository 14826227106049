import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    getAllUsers: (state, action) => {
      return action.payload;
    },
    addUser: (state, action) => {
      return [...state, action.payload];
    },
    updateUser: (state, action) => {
      return state.map((item, index) => {
        return action.payload.id === item.id
          ? {
              ...action.payload,
            }
          : item;
      });
    },
    deleteUser: (state, action) => {
      return state.filter((element) => element.id !== action.payload);
    },
    logoutUsers: (state, action) => {
      return [];
    },
  },
});

export const { getAllUsers, addUser, logoutUsers, updateUser, deleteUser } =
  usersSlice.actions;
export default usersSlice.reducer;
