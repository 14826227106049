import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { useSelector } from "react-redux";
import User from "../modal/user";
import UserSingle from "./userSingle";
import { styled } from "@mui/system";
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";
import UserListPhone from "./userListPhone";
import UserSinglePhone from "./userSinglePhone";
const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

export default function UsersList() {
  const [display, setDisplay] = useState(0);
  const users = useSelector((state) => state.users);
  const [inputText, setInputText] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [message, setMessage] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  let filteredData = users.filter((el) => {
    //if no input the return the original
    if (inputText === "") {
      return el;
    } else if (inputText === "active") {
      return el.subscription > -4;
    }
    //return the item which contains the user input
    else {
      return (
        el.name.toLowerCase().includes(inputText) ||
        el.email.toLowerCase().includes(inputText)
      );
    }
  });
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;
  return (
    <div className="overflow-x-auto relative shadow-md rounded-2xl">
      <div className="flex justify-between md:pr-14 pr-0 md:pt-2 pt-0 content-space mb-2 h-full ">
        <TextField
          id="outlined-basic"
          onChange={inputHandler}
          label="Search"
          sx={{ input: { color: "red" } }}
          className=" rounded"
          style={{ marginRight: "15px" }}
        />
        <button
          className=" font-bold  transition-all duration-500 text-sm btn"
          onClick={() => setOpen(true)}
        >
          Create User
        </button>
      </div>
      {filteredData.map((day, index) => {
        return index === display ? (
          <>
            <table className="text-sm text-left text-gray-500 dark:text-gray-400 rounded-xl w-full lg:table hidden">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 tableHead">
                <tr>
                  <th scope="col" className="py-3 px-6 text-center">
                    Fullname
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Email
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Roles
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Subscription Duration
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Subscription Type
                  </th>
                  <th scope="col" className="py-3 px-6 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {(rowsPerPage > 0
                  ? filteredData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredData
                ).map((row) => {
                  {
                    console.log(row);
                  }
                  return <UserSingle user={row} />;
                })}

                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={7} />
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      10,
                      15,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={8}
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    componentsProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </tr>
              </tfoot>
            </table>
            <UserListPhone filteredData={filteredData} />
          </>
        ) : (
          ""
        );
      })}{" "}
      <User
        isOpen={open}
        setIsOpen={setOpen}
        success={success}
        setSuccess={setSuccess}
        setError={setError}
        setMessage={setMessage}
      />
      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          User Created !
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
