import React, { useState } from "react";
import { useSelector } from "react-redux";
import Cl from "./cl";
import { useDrop } from "react-dnd";
import Square from "./Square";
import { IoIosAdd } from "react-icons/io";
import AddHour from "../modal/addHour";
import { AiOutlineDelete } from "react-icons/ai";
import Danger from "../modal/danger";
import axios from "axios";
import { useDispatch } from "react-redux";
import { deleteHours } from "../../redux/hoursSlice";
import instance from "../../apiInstance";
export default function DragDrop() {
  const classes = useSelector((state) => state.classes);
  const class_times = useSelector((state) => state.classTimes);
  const data = useSelector((state) => state.hours);
  const [open, setOpen] = useState(false);
  const [danger, setDanger] = useState(false);
  const [id, setId] = useState(0);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [board, setBoard] = useState([
    {
      id: 3,
      name: "Cross",
    },
  ]);
  const week = [
    { id: 0, day: "Monday" },
    { id: 1, day: "Tuesday" },
    { id: 2, day: "Wednesday" },
    { id: 3, day: "Thursday" },
    { id: 4, day: "Friday" },
    { id: 5, day: "Saturday" },
    { id: 6, day: "Sunday" },
  ];

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "classes",
    drop: (item) => addImageToBoard(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const addImageToBoard = (id) => {
    setBoard((board) => [...board, test[0]]);
  };
  const deleteHours2 = (e) => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/table-hours/${id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setDanger(false);
        dispatch(deleteHours(id));
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  return (
    <>
      <div className="classes">
        <div className="grid grid-cols-4 gap-4">
          {classes.map((cl) => {
            return <Cl id={cl.id} name={cl.name} color={cl.color} />;
          })}
        </div>
      </div>
      <div className="drop mt-16 mb-4">
        <table class="w-full mx-auto text-sm text-left schedule ">
          <thead className="text-xs  uppercase  text-center">
            <tr>
              <th scope="col" class="py-3 px-0"></th>
              <th scope="col" class="py-3 px-6"></th>
              <th scope="col" class="py-3 px-6">
                Monday
              </th>
              <th scope="col" class="py-3 px-6">
                Tuesday
              </th>
              <th scope="col" class="py-3 px-6">
                Wednesday
              </th>
              <th scope="col" class="py-3 px-6">
                Thursday
              </th>
              <th scope="col" class="py-3 px-6">
                Friday
              </th>
              <th scope="col" class="py-3 px-6">
                Saturday
              </th>
              <th scope="col" class="py-3 px-6">
                Sunday
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((time) => {
              return (
                <tr>
                  <th>
                    <div className="flex justify-center">
                      <form>
                        <AiOutlineDelete
                          size={28}
                          color="red"
                          className="mx-auto block cursor-pointer"
                          onClick={() => {
                            setId(time.id);
                            setDanger(true);
                          }}
                        />
                      </form>
                    </div>
                  </th>
                  <th className="text-center ">
                    <div>
                      {time.start_time}-{time.end_time}
                    </div>
                  </th>
                  {week.map((cl, index) => {
                    let flag = 0;
                    return (
                      <th classname="cursor-pointer">
                        {class_times
                          ? class_times.map((course) => {
                              switch (index) {
                                case 0:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                      />
                                    );
                                  }
                                  break;
                                case 1:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                      />
                                    );
                                  }
                                  break;
                                case 2:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                      />
                                    );
                                  }
                                  break;
                                case 3:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                      />
                                    );
                                  }
                                  break;
                                case 4:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                      />
                                    );
                                  }
                                  break;
                                case 5:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                      />
                                    );
                                  }
                                  break;
                                case 6:
                                  if (
                                    time.start_time === course.start_time &&
                                    time.end_time === course.end_time &&
                                    cl.day === course.days
                                  ) {
                                    flag = 1;
                                    return (
                                      <Square
                                        start={time.start_time}
                                        end={time.end_time}
                                        days={cl.day}
                                        name={cl.name}
                                        course={course}
                                      />
                                    );
                                  }
                                  break;
                                default:
                                  return "";
                              }
                            })
                          : ""}
                        {flag === 0 ? (
                          <Square
                            start={time.start_time}
                            end={time.end_time}
                            days={cl.day}
                            name={cl.name}
                          />
                        ) : (
                          ""
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}

            <tr className="text-center bg-transparent">
              <th></th>
              <IoIosAdd
                size={32}
                className="mx-auto border-2  rounded-full cursor-pointer addCircle "
                onClick={() => setOpen(true)}
              />
            </tr>
            <AddHour isOpen={open} setIsOpen={setOpen} />
            <Danger
              isOpen={danger}
              setIsOpen={setDanger}
              text="Are you sure you want to delete start and end time?"
              action={deleteHours2}
            />
          </tbody>
        </table>
      </div>
    </>
  );
}
