import { createSlice } from "@reduxjs/toolkit";

export const wodSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    getAllWods: (state, action) => {
      return action.payload;
    },
    addWod: (state, action) => {
      return [...state, action.payload];
    },
    updateWod: (state, action) => {
      return state.map((item, index) => {
        return action.payload.post.id === item.id
          ? {
              ...action.payload.post,
            }
          : item;
      });
    },
    deleteWod: (state, action) => {
      return state.filter((element) => element.id !== action.payload);
    },
    logoutWods: (state, action) => {
      return [];
    },
  },
});

export const { getAllWods, addWod, logoutWods, deleteWod, updateWod } =
  wodSlice.actions;
export default wodSlice.reducer;
