import { createSlice } from '@reduxjs/toolkit';

export const classTimesSlice = createSlice({
	name: 'classTimes',
	initialState: [],
	reducers: {
		getAllclassTimes: (state, action) => {
			return action.payload.classTimes;
		},
		addClassTime: (state, action) => {
			return [...state, action.payload];
		},
		updateClassTime: (state, action) => {
			return state.map((item, index) => {
				return action.payload.id === item.id
					? {
							...action.payload,
					  }
					: item;
			});
		},
		logoutClassTime: (state, action) => {
			return [];
		},
	},
});

export const {
	getAllclassTimes,
	logoutClassTime,
	addClassTime,
	updateClassTime,
} = classTimesSlice.actions;
export default classTimesSlice.reducer;
