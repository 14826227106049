import React, { useState } from "react";
import "./navbar.css";
import { BsFullscreen } from "react-icons/bs";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { BiMessageDetail } from "react-icons/bi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Divider, ListItemIcon } from "@mui/material";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { logoutBook } from "../../redux/booksSlice";
import { logoutClass } from "../../redux/classesSlice";
import { logoutClassTime } from "../../redux/classTimeSlice";
import { logoutUsers } from "../../redux/usersSlice";
import { logoutSubType } from "../../redux/subtypeSlice";
import { logoutSetting } from "../../redux/settingSlice";
import Cookies from "js-cookie";
import AuthApi from "../../AuthApi";
import instance from "../../apiInstance";
export default function Navbar({ handle, openSide, setOpenSide }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Auth = React.useContext(AuthApi);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const signout = () => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/logout`,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(logout());
        dispatch(logoutBook());
        dispatch(logoutClass());
        dispatch(logoutClassTime());
        dispatch(logoutUsers());
        dispatch(logoutSubType());
        dispatch(logoutSetting());
        Cookies.remove("user");
        navigate("/login");
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  return (
    <>
      <div
        className="text-3xl  h-[60px] grid grid-cols-9 fixed top-0 w-full navbar"
        style={{
          boxShadow:
            "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)",
          zIndex: 999,
        }}
      >
        <div
          className="lg:col-span-2 md:col-span-3 col-span-7"
          style={{ padding: "10px 20px 0px 30px" }}
        >
          <img
            src="./assets/LogoBarbox2.png"
            width={45}
            height={45}
            alt="barbox"
            style={{
              display: "inline-block",
              marginRight: 20,
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          />
          <span style={{ fontWeight: 700 }}>BarBox</span>
        </div>
        <div
          className="lg:col-span-1  items-center grid"
          style={{
            fontSize: 22,
            fontWeight: 200,
          }}
        >
          {openSide ? (
            <ChevronLeftIcon
              className="hamburger"
              onClick={() => setOpenSide(!openSide)}
            />
          ) : (
            <ChevronRightIcon
              className="hamburger"
              onClick={() => setOpenSide(!openSide)}
            />
          )}
        </div>
        <div
          className=" col-span-6 items-center justify-end lg:grid hidden pr-12"
          style={{
            fontSize: 22,
            fontWeight: 200,
          }}
        >
          <div className="inline-block ">
            {/* <BsFullscreen
              className="hamburger mx-2"
              style={{ display: "inline-block" }}
              onClick={handle.enter}
            /> */}
            {/* <MdOutlineNotificationsNone
              className="hamburger mx-2"
              style={{
                display: "inline-block",
                fontSize: 26,
              }}
            />
            <BiMessageDetail
              className="hamburger mx-2"
              style={{
                display: "inline-block",
                fontSize: 26,
              }}
            /> */}
            <img
              src={
                user.avatar === "default.jpg"
                  ? "./assets/LogoBarbox2.png"
                  : user.avatar
              }
              width={45}
              height={35}
              className={`inline-block rounded-full h-12 w-12 object-covertransition-all duration-300 z-10 mr-4`}
              alt="user"
            />
            <h2
              style={{ display: "inline-block" }}
              className="mx-2 cursor-pointer text-md"
              aria-controls="basic-menu"
              onClick={handleClick}
            >
              {user.name}
            </h2>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <MenuItem onClick={() => navigate("/settings")}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={() => signout()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
