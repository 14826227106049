import React, { useState, useRef } from "react";
import Navbar from "../components/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sidebar from "../components/sidebar/sidebar";
import Settings from "../components/settings/settings";
import SubscriptionTypes from "../components/subscription type/subscriptionTypes";
import SidebarPhone from "../components/sidebar/sidebarPhone";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import instance from "../apiInstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { addNotification, deleteNotification } from "../redux/notificationSlice";
import { AiOutlineDelete } from "react-icons/ai";
export default function Notifications({ theme, setSelectedTheme }) {
  const handle = useFullScreenHandle();
  const notifications = useSelector((state) => state.notifications);
  const user = useSelector((state) => state.user);
  const [openSide, setOpenSide] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const snackbarRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("title", data.title);
    formdata.append("content", data.content);
    formdata.append("start_date", formatDate(startDate));
    formdata.append("end_date", formatDate(endDate));
    instance({
      // url of the api endpoint (can be changed)
      url: "/web/notifications",
      data: formdata,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        dispatch(addNotification(res.data.notification));
        setSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
        setLoading(false);
      });
  };
  const removeNotification = (notif) => {
 
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/notifications/${notif.id}`,
      method: "Delete",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        dispatch(deleteNotification(notif.id));
        setSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
        setLoading(false);
      });
  };
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }
  return (
    <FullScreen handle={handle}>
      <Navbar handle={handle} openSide={openSide} setOpenSide={setOpenSide} />
      <div style={{ position: "absolute", top: 50, zIndex: 20 }}>
        <Sidebar openSide={openSide} />
        <SidebarPhone openSide={openSide} setOpenSide={setOpenSide} />
      </div>
      <div className="left-0 absolute">
        <div
          className={`content w-screen overflow-x-hidden h-screen ${
            openSide ? "lg:pl-[260px]" : "lg:pl-[70px]"
          } `}
          style={{ transition: "all 0.2s ease" }}
        >
          <h2 className="mt-28 pl-4" style={{ fontWeight: 700, fontSize: 22 }}>
            Create Notification
          </h2>
          <div className="mt-4 px-4">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col space-y-2 md:w-1/2 w-full mx-auto"
            >
              <input
                type="text"
                {...register("title")}
                placeholder="Title"
                className="outline-none  bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-100 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
              />
              <textarea
                {...register("content")}
                placeholder="Content"
                className="outline-none bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-100 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
              />
              <label>Start date</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={startDate}
                selected={startDate}
                startDate={startDate}
                onChange={(update) => {
                  setStartDate(update);
                }}
                withPortal
                className="w-full outline-none bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
              />
              <label>End date</label>

              <DatePicker
                dateFormat="dd/MM/yyyy"
                value={endDate}
                selected={endDate}
                startDate={endDate}
                onChange={(update) => {
                  setEndDate(update);
                }}
                withPortal
                className="w-full outline-none bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
              />
              <button
                type="submit"
                className="bg-[#f72d0a] py-5 md:px-10 px-2 rounded-md text-gray-200 font-bold text-lg"
              >
                Create
              </button>
            </form>
          </div>
          <div
            className={`content w-screen overflow-x-hidden h-full mt-12 ${
              openSide ? "lg:pl-[70px]" : "lg:pl-[70px]"
            } `}
            style={{ transition: "all 0.2s ease" }}
          >
            <div className="flex flex-row max-w-[80vw]">
              {notifications.map((notif) => {
                return (
                  <div className="wodPrev w-full max-w-[300px] rounded-lg p-4 relative">
                    <button className="p-2 bg-red-500 absolute right-2 top-0" onClick={()=>removeNotification(notif)}><AiOutlineDelete /></button>
                    <h1 className="justify-center flex">Notification</h1>
                    <h3>Title: {notif.title}</h3>
                    <h3>Content: {notif.content}</h3>
                    <h3>Start Date: {notif.start_date}</h3>
                    <h3>End Date: {notif.end_date}</h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="success"
            sx={{
              width: "100%",
              backgroundColor: "green",
              color: "white",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: "green",
              color: "white",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <Settings theme={theme} setSelectedTheme={setSelectedTheme} />
    </FullScreen>
  );
}
