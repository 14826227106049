import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sidebar from "../components/sidebar/sidebar";
import Settings from "../components/settings/settings";
import Cards from "../components/cards/cards";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getAllUsers } from "../redux/usersSlice";
import { getAllclasses } from "../redux/classesSlice";
import { getAllclassTimes } from "../redux/classTimeSlice";
import { getAllsubtypes } from "../redux/subtypeSlice";
import { getAllbooks } from "../redux/booksSlice";
import PeopleIcon from "@mui/icons-material/People";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SubtypesGraph from "../components/graphs/subtypesGraph";
import ActiveUsersPie from "../components/graphs/activeUsersPie";
import SubFullPassPie from "../components/graphs/subFullPassPie";
import { logout } from "../redux/userSlice";
import SidebarPhone from "../components/sidebar/sidebarPhone";
import { getSetting } from "../redux/settingSlice";
import { Alert, Snackbar } from "@mui/material";
export default function Home({ theme, setSelectedTheme, message }) {
  const handle = useFullScreenHandle();
  const [openSide, setOpenSide] = useState(true);
  const [show, setShow] = useState(false);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const books = useSelector((state) => state.books);
  const subtypes = useSelector((state) => state.subtypes);
  const active = users.filter((user) => user.subscription > -4);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const todaysBooks = books.filter(
    (book) => book.date === formatDate(new Date())
  );
  useEffect(() => {
    if (message > 0) {
      setShow(!show);
    }
  }, [message]);
  return (
    <FullScreen handle={handle}>
      <Navbar
        handle={handle}
        openSide={openSide}
        setOpenSide={setOpenSide}
        message={message}
      />
      <div style={{ position: "absolute", top: 50, zIndex: 20 }}>
        <Sidebar openSide={openSide} />
        <SidebarPhone openSide={openSide} setOpenSide={setOpenSide} />
      </div>
      <div className="left-0 absolute">
        <div
          className={`content w-screen overflow-x-hidden h-screen ${
            openSide ? "lg:pl-[260px]" : "lg:pl-[70px]"
          } `}
          style={{ transition: "all 0.2s ease" }}
        >
          <h2 className="mt-28 pl-4" style={{ fontWeight: 700, fontSize: 22 }}>
            Dashboard
          </h2>
          <div className="grid xl:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-4 mt-4 px-4">
            <Cards
              title="Total Users"
              number={users.length}
              colors={["#2ed8b6", "#59e0c5"]}
              dynamicMaxValue={1000}
              link="/users"
              icon=<PeopleIcon />
            />
            <Cards
              title="Active Users"
              number={active.length}
              colors={["#ffb64d", "#ffcb80"]}
              dynamicMaxValue={1000}
              link="/users"
              icon=<GroupAddIcon />
            />
            <Cards
              title="Today's Books"
              number={todaysBooks.length}
              colors={["#4099ff", "#73b4ff"]}
              dynamicMaxValue={100}
              link="/books"
              icon=<MenuBookIcon />
            />
            <Cards
              title="Subscription Types"
              number={subtypes.length}
              colors={["#ff5370", "#ff869a"]}
              dynamicMaxValue={10}
              link="/subtypes"
              icon=<MonetizationOnIcon />
            />
          </div>
          <div className="mt-16">
            <SubtypesGraph />
          </div>
          <div className="mt-20 grid md:grid-cols-2">
            <ActiveUsersPie />
            <SubFullPassPie />
          </div>
        </div>
      </div>
      <Settings theme={theme} setSelectedTheme={setSelectedTheme} />
    </FullScreen>
  );
}
