import React from "react";
import { useSelector } from "react-redux";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

export default function SubFullPassPie() {
  const subtypes = useSelector((state) => state.subtypes);
  const fullpass = subtypes.filter((subt) => subt.fullpass === 1);
  const test = [
    { id: 1, data: subtypes.length - fullpass.length, name: "No Fullpass" },
    { id: 2, data: fullpass.length, name: "Fullpass" },
  ];
  return (
    <div className="h-[300px]">
      <h3 className="text-center">Subscription Status</h3>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={test}
            dataKey="data"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            label
          >
            {test.map((entry, index) => (
              <Cell fill={index % 2 === 0 ? "gray" : "#3457e3"} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
