import React, { useState, useEffect } from "react";
import Singlerow from "./singlerow";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { addDays, format, getDate, parseISO, startOfWeek } from "date-fns";
import { useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function Book() {
  const [active, setActive] = useState("Monday");
  const hours = useSelector((state) => state.hours);
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [today, setToday] = useState(new Date(Date.now()));
  const classTimes = useSelector((state) => state.classTimes);
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let filteredData = classTimes.filter((el) => {
    //if no input the return the original
    if (el.days === days[startDate.getDay()]) {
      return el;
    }
  });
  filteredData.sort(function (a, b) {
    return b.start_time - a.start_time;
  });
  return (
    <div className="overflow-x-auto relative shadow-md rounded-2xl">
      <div className="flex items-center justify-center mt-4 mb-4 gap-4">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          value={startDate}
          selected={startDate}
          startDate={startDate}
          onChange={(update) => {
            setStartDate(update);
          }}
          withPortal
          className="cursor-pointer"
        />
      </div>

      {filteredData.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell c>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Class</TableCell>
                <TableCell>Students</TableCell>
                <TableCell>Limit</TableCell>
              </TableRow>
            </TableHead>

            {hours.map((hour) => {
              return filteredData.map((cl) => {
                if (
                  hour.start_time === cl.start_time &&
                  hour.end_time === cl.end_time
                ) {
                  return <Singlerow cl={cl} date={startDate} />;
                }
              });
            })}
          </Table>
        </TableContainer>
      ) : (
        <h2 className="text-center text-2xl">No Classes for this day</h2>
      )}
    </div>
  );
}
