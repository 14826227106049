import React, { useState, useEffect, Component } from "react";
import { ThemeProvider } from "styled-components";
import WebFont from "webfontloader";
import { GlobalStyles } from "./themes/GlobalStyles";
import { useTheme } from "./themes/useTheme";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./pages/home";
import Classes from "./pages/classes";
import Schedule from "./pages/schedule";
import Courses from "./pages/courses";
import Login from "./pages/login";
import { useDispatch, useSelector } from "react-redux";
import ProfileSettings from "./pages/profileSettings";
import axios from "axios";
import { logout } from "./redux/userSlice";
import { getSetting, logoutSetting } from "./redux/settingSlice";
import Users from "./pages/users";
import Subtypes from "./pages/subtypes";
import Books from "./pages/books";
import Configuration from "./pages/configuration";
import Wod from "./pages/wod";
import { getAllUsers, logoutUsers } from "./redux/usersSlice";
import { getAllclasses, logoutClass } from "./redux/classesSlice";
import { getAllclassTimes, logoutClassTime } from "./redux/classTimeSlice";
import { getAllsubtypes, logoutSubType } from "./redux/subtypeSlice";
import { getAllbooks, logoutBook } from "./redux/booksSlice";
import { getAllWods, logoutWods } from "./redux/wodSlice";
import Email from "./pages/email";
import { getAllHours, logoutHours } from "./redux/hoursSlice";
import AuthApi from "./AuthApi";
import Cookies from "js-cookie";
import Pusher from "pusher-js";
import { logoutposts } from "./redux/postSlice";
import instance from "./apiInstance";
import Layout from "./pages/layout";
import Notifications from "./pages/notifications";
import { getAllNotifications } from "./redux/notificationSlice";

function App() {
  const { theme, themeLoaded, getFonts } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [auth, setAuth] = React.useState(false);
  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme]);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const books = useSelector((state) => state.books);
  const subtypes = useSelector((state) => state.subtypes);
  const [message, setMessage] = useState(0);
  let messages = [];
  const readCookie = () => {
    const authinticated = Cookies.get("user");
    if (authinticated) {
      setAuth(true);
    }
  };
  useEffect(() => {
    readCookie();
    Pusher.logToConsole = true;
    const pusher = new Pusher("66a6174bad789a6c894c", { cluster: "eu" });
    const channel = pusher.subscribe("bookings");
    channel.bind("bookings.update", function (data) {
      messages.push(data);
      setMessage(messages.length);
    });
  }, []);
  useEffect(() => {
    if (user.token !== "" && user.role === "admin") {
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/books",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(getAllbooks(res.data.bookings));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
    }
  }, [message]);
  useEffect(() => {
    if (user.token !== "" && user.role === "admin") {
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/users",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(getAllUsers(res.data.users));
        })
        .catch((e) => {
          // handle error
          dispatch(logout());
        });
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/classes",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          let classes = res.data.classes;
          dispatch(getAllclasses({ classes }));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/sub-types",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          let subtypes = res.data.sub_types;
          dispatch(getAllsubtypes({ subtypes }));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/class-times",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          let classTimes = res.data.class_times;
          dispatch(getAllclassTimes({ classTimes }));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/books",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(getAllbooks(res.data.bookings));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/setting",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(getSetting(res.data.book_settings));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/wods",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(getAllWods(res.data.wods));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
      instance({
        // url of the api endpoint (can be changed)
        url: "/admin/table-hours",
        method: "GET",
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(getAllHours(res.data.hours));
        })
        .catch((e) => {
          // handle error
          console.error(e);
        });
        instance({
          // url of the api endpoint (can be changed)
          url: "/web/notifications",
          method: "GET",
          headers: { Authorization: "Bearer " + user.token },
        })
          .then((res) => {
            // handle success
            dispatch(getAllNotifications(res.data.notifications));
          })
          .catch((e) => {
            // handle error
            console.error(e);
          });
    }
  }, [user.token]);
  // 4: Load all the fonts

  return (
    <BrowserRouter>
      <AuthApi.Provider value={{ auth, setAuth }}>
        {themeLoaded && (
          <ThemeProvider theme={selectedTheme}>
            <GlobalStyles />
            <div className="App">
              <Routers
                selectedTheme={selectedTheme}
                setSelectedTheme={setSelectedTheme}
                message={message}
              />
            </div>
          </ThemeProvider>
        )}
      </AuthApi.Provider>
    </BrowserRouter>
  );
}
const Routers = ({ selectedTheme, setSelectedTheme, message }) => {
  const Auth = React.useContext(AuthApi);
  const user = useSelector((state) => state.user);
  const cookie = Cookies.get("user");
  const dispatch = useDispatch();
  useEffect(() => {
    if (Auth.auth) {
      setTimeout(function () {
        Cookies.remove("user");
        Auth.setAuth(false);
        dispatch(logout());
        dispatch(logoutBook());
        dispatch(logoutClass());
        dispatch(logoutClassTime());
        dispatch(logoutHours());
        dispatch(logoutSetting());
        dispatch(logoutSubType());
        dispatch(logoutUsers());
        dispatch(logoutWods());
        dispatch(logoutposts());
      }, new Date(user.exp) - new Date());
    }
  }, [cookie]);
  return (
    <Routes>
      {Auth.auth && user.role === "admin" ? (
        <>
          <Route
            exact
            path="/"
            element={
              <Layout
                children={
                  <Home
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/users"
            element={
              <Layout
                children={
                  <Users
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                    message={message}
                  />
                }
              />
            }
          />
           <Route
            exact
            path="/notification"
            element={
              <Layout
                children={
                  <Notifications
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                    message={message}
                  />
                }
              />
            }
          />
          <Route
            exact
            path="/subtypes"
            element={
              <Layout
                children={
                  <Subtypes
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/wod"
            element={
              <Layout
                children={
                  <Wod
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/email"
            element={
              <Layout
                children={
                  <Email
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/books"
            element={
              <Layout
                children={
                  <Books
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/configuration"
            element={
              <Layout
                children={
                  <Configuration
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/schedule"
            element={
              <Layout
                children={
                  <Schedule
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/classes"
            element={
              <Layout
                children={
                  <Classes
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/courses"
            element={
              <Layout
                children={
                  <Courses
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <Layout
                children={
                  <ProfileSettings
                    theme={selectedTheme}
                    setSelectedTheme={setSelectedTheme}
                  />
                }
                message={message}
              />
            }
          />
        </>
      ) : (
        <Route
          exact
          path="*"
          element={
            <Login theme={selectedTheme} setSelectedTheme={setSelectedTheme} />
          }
        />
      )}
    </Routes>
  );
};

export default App;
