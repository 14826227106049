import React, { useState, useRef } from "react";
import Navbar from "../components/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sidebar from "../components/sidebar/sidebar";
import Settings from "../components/settings/settings";
import SubscriptionTypes from "../components/subscription type/subscriptionTypes";
import SidebarPhone from "../components/sidebar/sidebarPhone";
import Slider from "react-input-slider";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import Alert from "@mui/material/Alert/Alert";
import { updateSetting } from "../redux/settingSlice";
import instance from "../apiInstance";
export default function Configuration({ theme, setSelectedTheme }) {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.setting);
  const [openSide, setOpenSide] = useState(true);
  const [limit, setLimit] = useState(settings[0] ? settings[0].class_limit : 0);
  const [sub, setSub] = useState(
    settings[0] ? settings[0].min_sub_required : 0
  );
  const [max, setMax] = useState(
    settings[0] ? settings[0].max_days_for_booking : 0
  );
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const snackbarRef = useRef(null);
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("class_limit", limit);
    formData.append("min_sub_required", sub);
    formData.append("max_days_for_booking", max);
    instance({
      // url of the api endpoint (can be changed)
      url: "/admin/setting/1/update",
      data: formData,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        setMessage(res.data.message);
        dispatch(updateSetting(res.data.book_settings));
      })
      .catch((e) => {
        // handle error
        setError(true);
        setMessage(error);
      });
  };
  return (
    <FullScreen handle={handle}>
      <Navbar handle={handle} openSide={openSide} setOpenSide={setOpenSide} />
      <div style={{ position: "absolute", top: 50, zIndex: 20 }}>
        <Sidebar openSide={openSide} />
        <SidebarPhone openSide={openSide} setOpenSide={setOpenSide} />
      </div>
      <div className="left-0 absolute">
        <div
          className={`content w-screen overflow-x-hidden h-screen ${
            openSide ? "lg:pl-[260px]" : "lg:pl-[70px]"
          } `}
          style={{ transition: "all 0.2s ease" }}
        >
          <h2 className="mt-28 pl-4" style={{ fontWeight: 700, fontSize: 22 }}>
            Configuration
          </h2>
          <div className="mt-4 px-4">
            <div>
              <h3>Class Limit</h3>
              <div className="flex flex-row items-center space-x-4">
                <Slider
                  axis={"x"}
                  x={limit}
                  xmax={50}
                  xmin={0}
                  xstep={1}
                  onChange={({ x }) => setLimit(x)}
                />
                <label className="text-xl bg-gray-200 rounded-xl p-1 text-gray-700">
                  {limit}
                </label>
              </div>
            </div>
            <div>
              <h3>Minimun Subscription</h3>
              <div className="flex flex-row items-center space-x-4">
                <Slider
                  axis={"x"}
                  x={sub}
                  xmax={50}
                  xmin={-4}
                  xstep={1}
                  onChange={({ x }) => setSub(x)}
                />
                <label className="text-xl bg-gray-200 rounded-xl p-1 text-gray-700">
                  {sub}
                </label>
              </div>
            </div>
            <div>
              <h3>Maximun days for Booking</h3>
              <div className="flex flex-row items-center space-x-4">
                <Slider
                  axis={"x"}
                  x={max}
                  xmax={50}
                  xmin={0}
                  xstep={1}
                  onChange={({ x }) => setMax(x)}
                />
                <label className="text-xl bg-gray-200 rounded-xl p-1 text-gray-700">
                  {max}
                </label>
              </div>
            </div>
          </div>
          <button
            className="bg-green-400 rounded-md cursor-pointer"
            onClick={() => handleSubmit()}
          >
            Save
          </button>
        </div>
        <Snackbar
          open={success}
          autoHideDuration={1000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="success"
            sx={{
              width: "100%",
              backgroundColor: "green",
              color: "white",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={1000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: "red",
              color: "white",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <Settings theme={theme} setSelectedTheme={setSelectedTheme} />
    </FullScreen>
  );
}
