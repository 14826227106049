import React, { useState, useEffect } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { getFromLS } from "../../utils/storage";
import "./settings.css";
import {
  CssBaseline,
  Divider,
  List,
  ListItem,
  SwipeableDrawer,
} from "@mui/material";
import { Box } from "@mui/system";
import { Global } from "@emotion/react";
import ThemeCard from "../themeCard/themeCard";
import { useTheme } from "../../themes/useTheme";

export default function Settings({ theme, setSelectedTheme }) {
  const themesFromStore = getFromLS("all-themes");
  const [data] = useState(themesFromStore.data);
  const [themes, setThemes] = useState([]);
  const { setMode } = useTheme();
  const themeSwitcher = (selectedTheme) => {
    setMode(selectedTheme);
    setSelectedTheme(selectedTheme);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    setThemes(_.keys(data));
  }, [data]);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
    >
      <List style={{ backgroundColor: "#252931", color: "white" }}>
        <ListItem
          className="text-white"
          style={{ fontFamily: "Ubuntu", fontWeight: 700 }}
        >
          Setting Panel
        </ListItem>
      </List>
      <List>
        <ListItem
          style={{
            color: "#96a2b4",
            fontFamily: "Poppins",
            fontWeight: 600,
          }}
        >
          Sidebar Color
        </ListItem>
        <ListItem>
          {themes.length > 0 &&
            themes.map((theme) => {
              return (
                <ThemeCard
                  theme={data[theme]}
                  key={theme.id}
                  themeSwitcher={themeSwitcher}
                />
              );
            })}
        </ListItem>
      </List>
      <Divider />
    </Box>
  );
  return (
    <>
      <div
        className="right-0 absolute block top-1/3 settings p-2 cursor-pointer"
        style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
        onClick={toggleDrawer("right", true)}
      >
        <SettingsIcon className="rotate" />
      </div>

      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(100% - 60px)`,
            overflow: "visible",
            position: "fixed",
            top: "60px",
            backgroundColor: "#2a3042",
            display: "flex",
            flexWrap: "wrap",
          },
        }}
        className="top-1/3"
      />
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
        sx={{
          height: 250,
        }}
      >
        {list("right")}
      </SwipeableDrawer>
    </>
  );
}
