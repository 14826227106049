import { createSlice } from '@reduxjs/toolkit';

export const subtypesSlice = createSlice({
	name: 'subtypes',
	initialState: [],
	reducers: {
		getAllsubtypes: (state, action) => {
			return action.payload.subtypes;
		},
		addSubType: (state, action) => {
			return [...state, action.payload];
		},
		deleteSubType: (state, action) => {
			return state.filter((element) => element.id !== action.payload);
		},
		updateSubType: (state, action) => {
			return state.map((item, index) => {
				return action.payload.id === item.id
					? {
							...action.payload,
					  }
					: item;
			});
		},
		logoutSubType: (state, action) => {
			return [];
		},
	},
});

export const {
	getAllsubtypes,
	logoutSubType,
	addSubType,
	deleteSubType,
	updateSubType,
} = subtypesSlice.actions;
export default subtypesSlice.reducer;
