import { TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { AiFillDelete, AiOutlineInfoCircle } from "react-icons/ai";
import axios from "axios";
import { useDispatch } from "react-redux";
import { deleteBook } from "../../redux/booksSlice";
import instance from "../../apiInstance";
export default function UserModal({ user, book }) {
  const [openModal, setOpenModal] = useState(false);
  const [del, setDel] = useState(false);
  const dispatch = useDispatch();
  const deleteBook2 = (book) => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/book/${book.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setDel(true);
        dispatch(deleteBook(book.id));
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  console.log(user);
  return (
    <>
      <TableRow>
        <TableCell>{book.user.name}</TableCell>
        <TableCell>
          <AiOutlineInfoCircle
            className="text-xl inline-block mx-auto"
            onClick={() => setOpenModal(true)}
          />
          <AiFillDelete
            className=" mx-auto text-red-400 cursor-pointer inline-block"
            size={32}
            onClick={() => deleteBook2(book)}
          />
        </TableCell>
      </TableRow>
      {openModal ? (
        <div
          id="myModal"
          className="modal mx-auto "
          style={{
            display: openModal ? "block" : "none",
          }}
        >
          <span
            className="close text-gray-800 mt-8"
            onClick={() => setOpenModal(false)}
          >
            &times;
          </span>
          <div className="modal-content w-full mt-32 border-2 rounded p-4">
            <h3 className="text-gray-500 text-xl mt-4 text-center">
              Username: {user.name}
            </h3>
            <h3 className="text-gray-500 text-xl mt-4 text-center">
              Email: {user.email}
            </h3>
            <h3 className="text-gray-500 text-xl mt-4 text-center">
              Subscription: {user.sub}
            </h3>
            <h3 className="text-gray-500 text-xl mt-4 text-center">
              Role: {user.role}
            </h3>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
