import React from 'react';
import '../settings/settings.css';
export default function ThemeCard({ theme, themeSwitcher }) {
	return (
		<div>
			<button
				className="color"
				style={{ backgroundColor: theme.colors.body }}
				onClick={() => themeSwitcher(theme)}
			></button>
		</div>
	);
}
