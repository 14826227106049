import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../redux/userSlice";
import { useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import AuthApi from "../AuthApi";
import Cookies from "js-cookie";
import instance from "../apiInstance";

export default function Login() {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Auth = React.useContext(AuthApi);
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state.user);
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    instance({
      // url of the api endpoint (can be changed)
      url: "/web/user/login",
      data: formdata,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        const curr = new Date();
        let name = res.data.user.name;
        let email = res.data.user.email;
        let avatar = res.data.user.avatar;
        let sub = res.data.user.subscription;
        let token = res.data.token;
        let role = res.data.user.roles;
        let id = res.data.user.id;
        let exp = new Date(curr.getTime() + 86400000).toString();
        Auth.setAuth(true);
        Cookies.set("user", "logged", {
          expires: new Date(curr.getTime() + 86400000),
        });
        navigate("/");
        dispatch(login({ name, email, avatar, sub, token, role, id, exp }));
      })
      .catch((e) => {
        // handle error
        setSuccess(true);
      });
  };
  useEffect(() => {
    if (user.token) {
      navigate("/");
    }
  }, []);
  return (
    <div className="flex items-center justify-center ">
      <div className=" w-screen lg:px-32 px-16 mx-auto border-2 h-screen bg-gray-300 lg:rounded-xl">
        <img
          src="/assets/LogoBarbox2.png"
          alt="logo"
          width={160}
          height={160}
          className="block mx-auto md:mt-12 mt-1"
        />
        <h3 className="text-center text-2xl md:mt-4 mt-2 font-bold text-gray-800">
          Login
        </h3>
        <form
          className=" lg:w-64 w-full block mx-auto py-2 mt-4 mb-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="block mx-auto">
            <label className="text-center text-xl text-gray-800 block">
              Email Address
            </label>
            {errors.email && (
              <p className="text-red-600">{errors.email.message}</p>
            )}
            <input
              type="text"
              {...register("email", {
                required: "Email is required",
              })}
              placeholder="Email address"
              style={{
                color: "#5f6061",
                border: errors.email ? "2px solid red" : "2px solid gray",
              }}
              className="w-[250px] my-2 p-2 rounded mx-auto block"
            />
          </div>
          <label className="text-center text-xl text-gray-800 block">
            Password
          </label>
          {errors.password && (
            <p className="text-red-600">{errors.password.message}</p>
          )}
          <input
            type="password"
            {...register("password", {
              required: "Password is required",
            })}
            placeholder="Password"
            style={{
              color: "#5f6061",
              border: errors.password ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded mx-auto block"
          />

          <input
            type="submit"
            value="Log in"
            className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-red-400 mt-8 transition-all duration-200 text-white font-bold"
          />
        </form>
        <Snackbar
          open={success}
          autoHideDuration={1000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: "red",
              color: "white",
            }}
          >
            Email or Password is not correct!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
