import { createSlice } from '@reduxjs/toolkit';

export const classesSlice = createSlice({
	name: 'classes',
	initialState: [],
	reducers: {
		getAllclasses: (state, action) => {
			return action.payload.classes;
		},
		updateClass: (state, action) => {
			return state.map((item, index) => {
				return action.payload.id === item.id
					? {
							...action.payload,
					  }
					: item;
			});
		},
		addClasses: (state, action) => {
			return [...state, action.payload];
		},
		deleteClass: (state, action) => {
			return state.filter((element) => element.id !== action.payload);
		},
		logoutClass: (state, action) => {
			return [];
		},
	},
});

export const {
	getAllclasses,
	logoutClass,
	updateClass,
	addClasses,
	deleteClass,
} = classesSlice.actions;
export default classesSlice.reducer;
