import React,{useState} from 'react'
import {BsPeopleFill} from 'react-icons/bs'
import {AiFillLock,AiFillUnlock} from 'react-icons/ai'
import Students from '../modal/students';
export default function Singlerow({lesson}) {
    const [locked,setLocked] = useState(false);
    const [open,setOpen] = useState(false);
    return (
        <tr className='tableRow text-end'>
            <td className='text-center'>
                {
                lesson.time
            }</td>
            <td className='text-center py-3'>
                {
                lesson.title
            }</td>
            <td className='text-center py-3'>
                {
                lesson.enrolled
            }</td>
            <td className='text-center py-3'>
                {
                lesson.limit
            }</td>
            <td className='text-center'>
                <BsPeopleFill size={25}
                    className="inline-block mx-2 cursor-pointer hamburger"
                    onClick={()=>setOpen(true)}
                />
                {
                    !locked?<AiFillUnlock className='inline-block cursor-pointer hamburger' size={25} color="#fff" onClick={()=>setLocked(true)}/>:<AiFillLock size={25} className="inline-block cursor-pointer" color="#fcba03" onClick={()=>setLocked(false)}/>
                }     
            </td>
            <Students isOpen={open} setIsOpen={setOpen} lesson={lesson}/>
        </tr>
    )
}
