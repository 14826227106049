import React from "react";
import { useSelector } from "react-redux";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

export default function ActiveUsersPie() {
  const users = useSelector((state) => state.users);
  const active = users.filter((user) => user.subscription > -4);
  const test = [
    { id: 1, data: users.length - active.length, name: "Inactive" },
    { id: 2, data: active.length, name: "Active" },
  ];
  return (
    <div className="h-[300px]">
      <h3 className="text-center">User's Status</h3>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={test}
            dataKey="data"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            label
          >
            {test.map((entry, index) => (
              <Cell fill={index % 2 === 0 ? "gray" : "#3457e3"} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
