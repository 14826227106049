import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import WebFont from "webfontloader";
import { useTheme } from "../../themes/useTheme";
import {
  AiOutlineDashboard,
  AiOutlineDatabase,
  AiOutlineEdit,
  AiOutlineSchedule,
  AiOutlineUser,
  AiOutlineDollarCircle,
  AiOutlineLogout,
  AiOutlineFileText,
} from "react-icons/ai";
import { IoFitnessOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import { logout } from "../../redux/userSlice";
import { logoutBook } from "../../redux/booksSlice";
import { logoutClass } from "../../redux/classesSlice";
import { logoutClassTime } from "../../redux/classTimeSlice";
import { logoutUsers } from "../../redux/usersSlice";
import { logoutSubType } from "../../redux/subtypeSlice";
import instance from "../../apiInstance";
export default function SidebarPhone({ openSide }) {
  const { theme, themeLoaded, getFonts } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [open, setOpen] = useState();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedTheme(theme);
  }, [themeLoaded, theme]);
  const signout = () => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/logout`,
      method: "POST",

      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(logout());
        dispatch(logoutBook());
        dispatch(logoutClass());
        dispatch(logoutClassTime());
        dispatch(logoutUsers());
        dispatch(logoutSubType());
        navigate("/");
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  // 4: Load all the fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: getFonts(),
      },
    });
  });
  useEffect(() => {
    setOpen(openSide);
  }, [openSide]);

  return (
    <div
      style={{
        overflowX: "hidden",
        transition: "all 0.2s ease",
      }}
      className={`fixed top-[30px] h-screen lg:hidden block ${
        open ? "lg:[240px]" : "lg:w-[60px] w-[0px]"
      } navbar`}
    >
      <Toolbar />
      <Box sx={{ top: 50 }}>
        <List
          style={{
            opacity: open ? "1" : "0",
            width: open ? 240 : 0,
            transition: "all ease 0.2s",
          }}
        >
          <ListItem>
            <img
              src={
                user.avatar === "default.jpg"
                  ? "./assets/LogoBarbox2.png"
                  : user.avatar
              }
              className={`block rounded-full h-20 w-20 mx-auto object-cover cursor-pointer transition-all duration-300`}
              alt="profile"
            />
          </ListItem>
          <ListItem className="text-center" style={{ paddingTop: 0 }}>
            <h3 className="mx-auto block text-gray-300">{user.name}</h3>
          </ListItem>
          <ListItem className="text-center" style={{ paddingTop: 0 }}>
            <h3 className="mx-auto block text-gray-400 text-sm">
              Administrator
            </h3>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => navigate("/")}>
            <ListItemIcon>
              <AiOutlineDashboard size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Dashboard</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/users")}>
            <ListItemIcon>
              <AiOutlineUser size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Users</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/books")}>
            <ListItemIcon>
              <AiOutlineDatabase size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Books</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/subtypes")}>
            <ListItemIcon>
              <AiOutlineDollarCircle size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Subscription</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          {/* <ListItem button onClick={() => navigate('/classes')}>
						<ListItemIcon>
							<AiOutlineEdit size={25} />
						</ListItemIcon>
						{open ? (
							<ListItemText>
								<h2 className="">Wod</h2>
							</ListItemText>
						) : (
							''
						)}
					</ListItem> */}
          <ListItem button onClick={() => navigate("/schedule")}>
            <ListItemIcon>
              <AiOutlineSchedule size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Schedule</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/courses")}>
            <ListItemIcon>
              <IoFitnessOutline size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Classes</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/wod")}>
            <ListItemIcon>
              <AiOutlineFileText size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Wod</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
        </List>
        <Divider />
        <ListItem button onClick={signout}>
          <ListItemIcon>
            <AiOutlineLogout size={25} className="icon" />
          </ListItemIcon>
          {open ? (
            <ListItemText>
              <h2 className="">Logout</h2>
            </ListItemText>
          ) : (
            ""
          )}
        </ListItem>
        {/* <List>
					{['All mail', 'Trash', 'Spam'].map((text, index) => (
						<ListItem button key={text}>
							<ListItemIcon>
								{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
							</ListItemIcon>
							{open ? <ListItemText primary={text} /> : ''}
						</ListItem>
					))}
				</List> */}
      </Box>
    </div>
  );
}
