import htmlToDraft from "html-to-draftjs";
import React, { useState, useMemo } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import Danger from "../modal/danger";
import axios from "axios";
import { useDispatch } from "react-redux";
import { deleteWod, updateWod } from "../../redux/wodSlice";
import { Alert, Snackbar } from "@mui/material";
import { deletepost, updatePost } from "../../redux/postSlice";
import instance from "../../apiInstance";
import CreateWod from "./createWod";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ReactDatePicker from "react-datepicker";
import draftToHtml from "draftjs-to-html";
function SingleWod({
  wod,
  index,
  active,
  setActive,
  setSuccess,
  setError,
  setMessage,
}) {
  const [open, setIsOpen] = useState(false);
  const [editor, setEditor] = useState(false);
  const [title, setTitle] = useState(wod.title);
  const dateFormat = wod.date.split("/");
  const html = wod.body;

  const blocksFromHTML = convertFromHTML(html);
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [date, setDate] = useState(
    new Date(dateFormat[2], dateFormat[1] - 1, dateFormat[0])
  );
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(content)
  );
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const cleanHTML = DOMPurify.sanitize(wod.body, {
    USE_PROFILES: { html: true },
  });
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const deletePost = () => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/posts/${wod.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setIsOpen(false);
        dispatch(deletepost(wod.id));
        setMessage("Wod deleted!");
        setSuccess(true);
        setActive(0);
      })
      .catch((e) => {
        setMessage("Something went wrong!");
        setError(true);
      });
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("type", "wod");
    formData.append("title", title);
    formData.append(
      "body",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    formData.append("date", formatDate(date));
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/posts/${wod.id}/update`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        setSuccess(true);
        let post = res.data.post;
        post["id"] = wod.id;
        dispatch(updateWod({ post: post }));
        setEditor(!editor);
      })
      .catch((e) => {
        // handle error
      });
  };
  return (
    <>
      <div
        className={`${active === index && !editor ? "block" : "hidden"} py-12`}
      >
        <div className={`flex justify-between ml-4 mr-4 mt-2 gap-12 `}>
          <button className="bg-green-500" onClick={() => setEditor(!editor)}>
            Edit
          </button>
          <button className="bg-red-500" onClick={() => setIsOpen(true)}>
            Delete
          </button>
        </div>
        <h2 className="text-2xl tracking-widest text-center self-center basis-full">
          {wod.title}
        </h2>

        <h3>{wod.date}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: cleanHTML }}
          className="mt-4"
        ></div>
        <Danger
          isOpen={open}
          setIsOpen={setIsOpen}
          text="Are you sure you want to delete this post?"
          action={deletePost}
        />
      </div>
      <div
        className={`${active === index && editor ? "block" : "hidden"} py-6`}
      >
        <div className="flex flex-col mb-6">
          <div className="flex justify-center items-center mb-6">
            <button className="bg-green-600 mr-4 mt-6" onClick={handleSubmit}>
              Submit
            </button>
          </div>
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            value={date}
            selected={date}
            startDate={date}
            onChange={(update) => {
              setDate(update);
            }}
            withPortal
            className="text-gray-700 cursor-pointer flex justify-center items-center text-center mx-auto rounded-lg"
          />
          <label>Title</label>
          <input
            type="text"
            value={title}
            className="text-gray-800 rounded-lg p-2"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div
          className="bg-gray-200 text-black rounded-xl"
          style={{ minHeight: "400px" }}
        >
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      </div>
    </>
  );
}
function SinglePost({
  wod,
  index,
  active,
  setActive,
  setSuccess,
  setError,
  setMessage,
}) {
  const [open, setIsOpen] = useState(false);
  const [editor, setEditor] = useState(false);
  const [title, setTitle] = useState(wod.title);
  const dateFormat = wod.date.split("/");
  const html = wod.body;

  const blocksFromHTML = convertFromHTML(html);
  const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [date, setDate] = useState(
    new Date(dateFormat[2], dateFormat[1] - 1, dateFormat[0])
  );
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(content)
  );
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const cleanHTML = DOMPurify.sanitize(wod.body, {
    USE_PROFILES: { html: true },
  });
  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }
  const deletePost = () => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/posts/${wod.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setIsOpen(false);
        dispatch(deletepost(wod.id));
        setMessage("Post deleted!");
        setSuccess(true);
        setActive(0);
      })
      .catch((e) => {
        setMessage("Something went wrong!");
        setError(true);
      });
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("type", "post");
    formData.append("title", title);
    formData.append(
      "body",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    formData.append("date", formatDate(date));
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/posts/${wod.id}/update`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        setSuccess(true);
        let post = res.data.post;
        post["id"] = wod.id;
        dispatch(updatePost({ post: post }));
        setEditor(!editor);
      })
      .catch((e) => {
        // handle error
      });
  };
  return (
    <>
      <div
        className={`${
          active === index && !editor ? "block" : "hidden"
        } py-12 wodPrev`}
      >
        <div className={`flex justify-between ml-4 mr-4 mt-2 gap-12`}>
          <button className="bg-green-500" onClick={() => setEditor(!editor)}>
            Edit
          </button>
          <button className="bg-red-500" onClick={() => setIsOpen(true)}>
            Delete
          </button>
        </div>
        <h2 className="text-2xl tracking-widest text-center self-center basis-full">
          {wod.title}
        </h2>

        <h3>{wod.date}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: cleanHTML }}
          className="mt-4"
        ></div>
        <Danger
          isOpen={open}
          setIsOpen={setIsOpen}
          text="Are you sure you want to delete this post?"
          action={deletePost}
        />
      </div>
      <div
        className={`${active === index && editor ? "block" : "hidden"} py-6`}
      >
        <div className="flex flex-col mb-6">
          <div className="flex justify-center items-center mb-6">
            <button className="bg-green-600 mr-4 mt-6" onClick={handleSubmit}>
              Submit
            </button>
          </div>
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            value={date}
            selected={date}
            startDate={date}
            onChange={(update) => {
              setDate(update);
            }}
            withPortal
            className="text-gray-700 cursor-pointer flex justify-center items-center text-center mx-auto rounded-lg"
          />
          <label>Title</label>
          <input
            type="text"
            value={title}
            className="text-gray-800 rounded-lg p-2"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div
          className="editor text-black rounded-xl"
          style={{ minHeight: "400px" }}
        >
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      </div>
    </>
  );
}
export default function PreviousWods({ type, setDate }) {
  const wods = useSelector((state) => state.wod);
  const posts = useSelector((state) => state.post);
  const [active, setActive] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const dataPrevious = useMemo(() => {
    let arr = [];
    for (let i = 0; i < wods.length; i++) {
      const date = new Date();
      date.setHours(0, 0, 0);
      const wodDate = wods[i].date.split("/");
      const wodDate2 = new Date(
        Number(wodDate[2]),
        Number(wodDate[1]) - 1,
        Number(wodDate[0])
      );
      if (wodDate2 === date) {
        setActive(i);
        arr.push(wods[i]);
      } else if (wodDate2 > new Date()) {
        continue;
      } else {
        arr.push(wods[i]);
      }
    }
    return arr.sort(function (a, b) {
      var aa = a.date.split("/").reverse().join(),
        bb = b.date.split("/").reverse().join();
      return aa > bb ? -1 : aa < bb ? 1 : 0;
    });
  }, [wods]);
  return (
    <div className="text-center mt-4">
      <h3 className="text-2xl tracking-widest">All {type}s</h3>
      <div className="flex flex-row justify-between">
        <button className="bg-transparent">
          <AiOutlineArrowLeft
            onClick={() => {
              if (type === "wod") {
                if (active + 1 > wods.length - 1) {
                  setActive(0);
                } else {
                  setActive(active + 1);
                }
              }
            }}
            size={32}
            className="border-2 rounded-full addCircle"
          />
        </button>
        <button className="bg-transparent">
          <AiOutlineArrowRight
            onClick={() => {
              if (type === "wod") {
                if (active - 1 < 0) {
                  setActive(0);
                } else {
                  setActive(active - 1);
                }
              }
            }}
            size={32}
            className="border-2 rounded-full addCircle"
          />
        </button>
      </div>
      <div className="flex flex-row">
        <div className="h-full w-full wodPrev mb-12 rounded-lg min-h-[300px]">
          {type === "wod"
            ? dataPrevious.map((wod, index) => {
                return (
                  <SingleWod
                    wod={wod}
                    index={index}
                    active={active}
                    setActive={setActive}
                    setMessage={setMessage}
                    setSuccess={setSuccess}
                    setError={setError}
                  />
                );
              })
            : posts
            ? posts.map((wod, index) => {
                return (
                  <SinglePost
                    wod={wod}
                    index={index}
                    active={active}
                    setActive={setActive}
                    setMessage={setMessage}
                    setSuccess={setSuccess}
                    setError={setError}
                  />
                );
              })
            : ""}
        </div>
      </div>
      <Snackbar
        open={success}
        autoHideDuration={1000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={1000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
