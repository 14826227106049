import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    name: "",
    email: "",
    avatar: "",
    sub: "",
    token: "",
    role: "",
    id: "",
    exp: Date,
  },
  reducers: {
    login: (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.token = action.payload.token;
      state.avatar = action.payload.avatar;
      state.sub = action.payload.sub;
      state.role = action.payload.role;
      state.id = action.payload.id;
      state.exp = action.payload.exp;
    },
    updatePhoto: (state, action) => {
      state.avatar = action.payload;
    },
    updateData: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
    },
    logout: (state, action) => {
      state.email = "";
      state.name = "";
      state.token = "";
      state.avatar = "";
      state.role = "";
      state.id = "";
      state.sub = 0;
      state.expiresIn = "";
      state.exp = "";
    },
  },
});

export const { login, logout, updatePhoto, updateData } = userSlice.actions;
export default userSlice.reducer;
