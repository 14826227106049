import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }
  .fullscreen-enabled{
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }

  .content{
    background: ${({ theme }) => theme.colors.page};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }
  .navbar{
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }
  .tableHead{
    background: ${({ theme }) => theme.colors.table};
    color: ${({ theme }) => theme.colors.text};
  }
  .tableRow{
    background: ${({ theme }) => theme.colors.row};
    color: ${({ theme }) => theme.colors.text};
  }
  .css-bsph2u-MuiTableCell-root{
    color: ${({ theme }) => theme.colors.text};
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
    color: ${({ theme }) => theme.colors.cards};
  }
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    color: ${({ theme }) => theme.colors.cards};
  }
  .settings{
    background: #6777ef;
  }
  
  thead{
    background: ${({ theme }) => theme.colors.table};
  }
  tbody{
    background: ${({ theme }) => theme.colors.row};
  }
  table{
    background: ${({ theme }) => theme.colors.table};
  }
  .css-1q1u3t4-MuiTableRow-root{
    color:${({ theme }) => theme.colors.text}
  }
  .css-1howxi1-MuiTableCell-root{
    color:${({ theme }) => theme.colors.cards}
  }
  .css-6qfsqn-MuiTableCell-root{
    color:${({ theme }) => theme.colors.cards}
  }
  .css-12q85cb-MuiTableCell-root{
    color:${({ theme }) => theme.colors.cards}
  }
  .css-1azl6jz-MuiTableCell-root{
    color: ${({ theme }) => theme.colors.cards};
  }
  .header{
    background: ${({ theme }) => theme.colors.table};
    color: ${({ theme }) => theme.colors.cards};
  }
  .css-1ygcj2i-MuiTableCell-root{
    color:${({ theme }) => theme.colors.cards}
  }
  td{
    background:${({ theme }) => theme.colors.row}
  }
  th{
    color:${({ theme }) => theme.colors.cards}
  }
  h3{
    color:${({ theme }) => theme.colors.text}
  }
  .hamburger{
    color: ${({ theme }) => theme.colors.text};
  }
  .addCircle{
    color: ${({ theme }) => theme.colors.text};
    border-color:${({ theme }) => theme.colors.text};
  }
  h4{
    color:${({ theme }) => theme.colors.cards};
  }
  .icon{
    color: ${({ theme }) => theme.colors.text};
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    color: ${({ theme }) => theme.colors.text};
  }
  .css-1ex1afd-MuiTableCell-root{
    color: ${({ theme }) => theme.colors.text};
  }
  .schedule {
    background: ${({ theme }) => theme.colors.page};
    color: ${({ theme }) => theme.colors.text}
  }
  table.schedule tr {
    background: ${({ theme }) => theme.colors.page};
    color: ${({ theme }) => theme.colors.text}
  }
  table.schedule th {
    background: ${({ theme }) => theme.colors.page};
    color: ${({ theme }) => theme.colors.text}
  }
  
  select{
    background: ${({ theme }) => theme.colors.row};
    color:${({ theme }) => theme.colors.text};
  }
  input{
    background: ${({ theme }) => theme.colors.row};
    color: ${({ theme }) => theme.colors.text};
  }
  textarea{
    background: ${({ theme }) => theme.colors.row};
    color: ${({ theme }) => theme.colors.text};
  }
  label{
    color: ${({ theme }) => theme.colors.label};
  }
  a {
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
  }
  .editor{
    background: ${({ theme }) => theme.colors.row};
  }
  .wodButton{
    color: ${({ theme }) => theme.colors.text};
  }
  .buttonLabel{
    color: ${({ theme }) => theme.colors.text};
  }
  .wodPrev{
    background: ${({ theme }) => theme.colors.row};
    color: ${({ theme }) => theme.colors.cards};
  }
  p{
    color:${({ theme }) => theme.colors.text};
  }
  input::placeholder{
    color:${({ theme }) => theme.colors.text};
  }
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    color:${({ theme }) => theme.colors.text};
  }
  .MuiTablePaginationUnstyled-select{
    background:${({ theme }) => theme.colors.body};
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    background:${({ theme }) => theme.colors.row};
  }
  h2{
    color:${({ theme }) => theme.colors.text};
  }
  svg{
    color: ${({ theme }) => theme.colors.svg};
  }
  button,[type="submit"] {
    border: 0;

    padding: 12px 24px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
    font-family: ${({ theme }) => theme.font};
  }
  
  button:hover{
    background-color: ${({ theme }) => theme.colors.button.hover_bg};
    transition: all 0.5s ease-out;
    color: ${({ theme }) => theme.colors.label};
  }
  input::placeholder { 
    color: white;
    opacity: 1; 
  }
  button.btn {
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
  }
  button.btn:hover {
    background-color: ${({ theme }) => theme.colors.button.hover_bg};
    color: ${({ theme }) => theme.colors.label};
  }
  .react-datepicker__input-container{
    color: ${({ theme }) => theme.colors.button.label};
    background: ${({ theme }) => theme.colors.row};
  }
 
`;
