import { Alert, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";

export default function Layout({ children, message }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (message > 0) {
      setShow(!show);
    }
  }, [message]);
  return (
    <div>
      {children}
      <Snackbar
        open={show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setShow(false)}
      >
        <Alert
          onClose={() => setShow(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          New booking!
        </Alert>
      </Snackbar>
    </div>
  );
}
