import { createSlice } from "@reduxjs/toolkit";

export const postSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    getAllposts: (state, action) => {
      return action.payload;
    },
    addpost: (state, action) => {
      return [...state, action.payload];
    },
    updatePost: (state, action) => {
      return state.map((item, index) => {
        return action.payload.post.id === item.id
          ? {
              ...action.payload.post,
            }
          : item;
      });
    },
    deletepost: (state, action) => {
      console.log(action.payload);
      return state.filter((element) => element.id !== action.payload);
    },
    logoutposts: (state, action) => {
      return [];
    },
  },
});

export const { getAllposts, addpost, logoutposts, deletepost, updatePost } =
  postSlice.actions;
export default postSlice.reducer;
