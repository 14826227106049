import React from "react";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragDrop from "./DragDrop";
import axios from "axios";
export default function Schedulemodify() {
  const classes = useSelector((state) => state.classes);
  const user = useSelector((state) => state.user);

  return (
    <>
      <div>
        <DndProvider backend={HTML5Backend}>
          <DragDrop />
        </DndProvider>
      </div>
    </>
  );
}
