import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { GiCancel } from "react-icons/gi";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubType, updateSubType } from "../../redux/subtypeSlice";
import Danger from "../modal/danger";
import Subscription from "../modal/subscription";
import instance from "../../apiInstance";
export default function SubscriptionSingle({ sub }) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const user = useSelector((state) => state.user);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setValue("subscription_name", sub.subscription_name);
    setValue("description", sub.subscription_description);
    setValue("price", sub.price_per_month);
    setValue("fullpass", sub.fullpass);
    setValue("day_limit", sub.days_limit);
  }, []);
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("subscription_name", data.subscription_name);
    formdata.append("subscription_description", data.description);
    formdata.append("price_per_month", data.price);
    formdata.append("fullpass", data.fullpass);
    formdata.append("days_limit", data.day_limit);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/sub-types/${sub.id}/update`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        dispatch(updateSubType(res.data.sub_type));
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
      });
  };
  const deleteSubscription = () => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/sub-types/${sub.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        dispatch(deleteSubType(sub.id));
      })
      .catch((e) => {
        // handle error
      });
  };
  return (
    <div className="border-2 border-gray-300  h-full w-full text-center pb-12 pt-4 px-2 text-xl rounded-lg sub_border">
      <div className="flex justify-end">
        <GiCancel
          className="text-red-500 cursor-pointer"
          size={25}
          onClick={() => setOpen(true)}
        />
      </div>
      <form
        className=" w-64 block mx-auto py-2 mt-4 mb-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {errors.subscription_name && (
          <p className="text-red-600">{errors.subscription_name.message}</p>
        )}
        <label>Name</label>
        <input
          type="text"
          {...register("subscription_name", {
            required: "Subscription name is required",
          })}
          placeholder="Subscription name"
          style={{
            border: errors.subscription_name
              ? "2px solid red"
              : "2px solid gray",
          }}
          className="w-full my-2 p-2 rounded "
        />
        {errors.description && (
          <p className="text-red-600">{errors.description.message}</p>
        )}
        <label>Description</label>
        <textarea
          type="text"
          {...register("description", {
            required: "Subscription Description is required",
          })}
          placeholder="Subscription Description"
          style={{
            border: errors.description ? "2px solid red" : "2px solid gray",
          }}
          className="p-2 w-full my-2 rounded "
        />
        {errors.day_limit && (
          <p className="text-red-600">{errors.day_limit.message}</p>
        )}
        <label>Course limit per week</label>
        <input
          type="number"
          {...register("day_limit", {
            required: "Course limit is required",
          })}
          placeholder="Course limit"
          style={{
            border: errors.price ? "2px solid red" : "2px solid gray",
          }}
          className="p-2 w-[250px] my-2 rounded "
        />
        {errors.price && <p className="text-red-600">{errors.price.message}</p>}
        <label>Price</label>
        <input
          type="number"
          {...register("price", {
            required: "Price is required",
          })}
          placeholder="Price"
          style={{
            border: errors.price ? "2px solid red" : "2px solid gray",
          }}
          className="p-2 w-[250px] my-2 rounded "
        />
        {errors.fullpass && (
          <p className="text-red-600">{errors.fullpass.message}</p>
        )}
        <label>Full Pass</label>
        <select
          {...register("fullpass")}
          style={{
            border: errors.price ? "2px solid red" : "2px solid gray",
          }}
          className="p-2 w-[250px] my-2 rounded "
        >
          <option value="1" selected>
            True
          </option>
          <option value="0">False</option>
        </select>
        <button
          type="submit"
          className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer  hover:bg-green-400 mt-8 transition-all duration-200 text-white font-bold"
        >
          Save
        </button>
      </form>
      <Danger
        isOpen={open}
        setIsOpen={setOpen}
        text={`Are you sure you want to delete subscription ${sub.subscription_name}`}
        action={deleteSubscription}
      />
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Subscription Updated
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
