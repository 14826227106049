import React, { useState } from "react";
import "./modal.css";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addSubType } from "../../redux/subtypeSlice";
import { SketchPicker } from "react-color";
import { addClasses } from "../../redux/classesSlice";
import instance from "../../apiInstance";
export default function Classes({ isOpen, setIsOpen, success, setSuccess }) {
  const user = useSelector((state) => state.user);
  const subtypes = useSelector((state) => state.subtypes);
  const [color2, setColor] = useState("");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let formdata = new FormData();
    formdata.append("subscription_type_id", data.subtypes);
    formdata.append("name", data.class_name);
    formdata.append("description", data.description);
    formdata.append("color", color2);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/classes`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        dispatch(addClasses(res.data.class));
        setIsOpen(false);
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto "
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <span className="close" onClick={() => setIsOpen(false)}>
        &times;
      </span>
      <div className="modal-content mx-auto mt-32 border-2 rounded p-4">
        <h3 className="text-gray-500 text-3xl mt-4 text-center">
          Add new Class
        </h3>
        <form
          className=" w-64 block mx-auto py-2 mt-4 mb-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <label className="text-center text-xl text-gray-800">
            Class Name
          </label>
          {errors.class_name && (
            <p className="text-red-600">{errors.class_name.message}</p>
          )}
          <input
            type="text"
            {...register("class_name", {
              required: "Class name is required",
            })}
            placeholder="Class name"
            style={{
              color: "#5f6061",
              border: errors.class_name ? "2px solid red" : "2px solid gray",
            }}
            className="w-[250px] my-2 p-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">
            Class Description
          </label>
          {errors.description && (
            <p className="text-red-600">{errors.description.message}</p>
          )}
          <textarea
            type="text"
            {...register("description", {
              required: "Subscription Description is required",
            })}
            placeholder="Subscription Description"
            style={{
              color: "#5f6061",
              border: errors.description ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          />
          <label className="text-center text-xl text-gray-800">
            Subscription type
          </label>
          {errors.subtypes && (
            <p className="text-red-600">{errors.subtypes.message}</p>
          )}
          <select
            {...register("subtypes")}
            style={{
              color: "#5f6061",
              border: errors.subtypes ? "2px solid red" : "2px solid gray",
            }}
            className="p-2 w-[250px] my-2 rounded"
          >
            {subtypes.map((sub) => {
              return <option value={sub.id}>{sub.subscription_name}</option>;
            })}
          </select>
          <SketchPicker
            color={color2}
            onChange={(color) => setColor(color.hex)}
          />
          <input
            type="submit"
            value="Create"
            className=" mx-auto block my-2 p-2  rounded w-32 cursor-pointer bg-gray-700 hover:bg-red-400 mt-8 transition-all duration-200 text-white font-bold"
          />
        </form>
      </div>
    </div>
  );
}
