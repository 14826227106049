import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./cards.css";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
export default function Cards({
  title,
  number,
  colors,
  message,
  dynamicMaxValue,
  link,
  icon,
}) {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(link);
  };
  return (
    <div
      style={{
        background: `linear-gradient(45deg,${colors[0]},${colors[1]})`,
        minHeight: 100,
        width: "100%",
        padding: 15,
        borderRadius: 10,
        cursor: "pointer",
      }}
      onClick={navigateTo}
    >
      <span className="icon-span">{icon}</span>
      <div className="info-box">
        <span
          className="text-white block"
          style={{
            fontSize: 18,
            whiteSpace: "nowrap",
            fontWeight: 400,
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </span>
        <span
          className="text-white block"
          style={{
            fontSize: 22,
            whiteSpace: "nowrap",
            fontWeight: 400,
            textOverflow: "ellipsis",
          }}
        >
          {number}
        </span>
        <LinearProgress
          variant="determinate"
          value={(number * 100) / dynamicMaxValue}
          color="primary"
          className="my-2"
        />
        <span
          className="text-white block mx-auto"
          style={{
            fontSize: 18,
            fontWeight: 400,
            textOverflow: "ellipsis",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {message}
        </span>
      </div>
    </div>
  );
}
