import React, { useState, useRef } from "react";
import Navbar from "../components/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sidebar from "../components/sidebar/sidebar";
import Settings from "../components/settings/settings";
import SubscriptionTypes from "../components/subscription type/subscriptionTypes";
import SidebarPhone from "../components/sidebar/sidebarPhone";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import instance from "../apiInstance";
export default function Email({ theme, setSelectedTheme }) {
  const handle = useFullScreenHandle();

  const user = useSelector((state) => state.user);
  const [openSide, setOpenSide] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const snackbarRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("subject", data.subject);
    formdata.append("message", data.message);
    instance({
      // url of the api endpoint (can be changed)
      url: "/admin/active-users-mail",
      data: formdata,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        setSuccess(true);
        setLoading(false);
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
        setLoading(false);
      });
  };
  return (
    <FullScreen handle={handle}>
      <Navbar handle={handle} openSide={openSide} setOpenSide={setOpenSide} />
      <div style={{ position: "absolute", top: 50, zIndex: 20 }}>
        <Sidebar openSide={openSide} />
        <SidebarPhone openSide={openSide} setOpenSide={setOpenSide} />
      </div>
      <div className="left-0 absolute">
        <div
          className={`content w-screen overflow-x-hidden h-screen ${
            openSide ? "lg:pl-[260px]" : "lg:pl-[70px]"
          } `}
          style={{ transition: "all 0.2s ease" }}
        >
          <h2 className="mt-28 pl-4" style={{ fontWeight: 700, fontSize: 22 }}>
            Email
          </h2>
          <div className="mt-4 px-4">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col space-y-2 md:w-1/2 w-full mx-auto"
            >
              <input
                type="text"
                {...register("subject")}
                placeholder="Subject"
                className="outline-none bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
              />
              <textarea
                {...register("message")}
                placeholder="Message"
                className="outline-none bg-slate-400/10 rounded-sm border-b border-[#242424] px-6 py-4 text-gray-500 transition-all placeholder-gray-500 focus:border-[#f72d0a]/40 focus:text-[#f72d0a]/40 hover:border-[#f72d0a]/40 hover:text-[#f72d0a]/40"
              />
              <button
                type="submit"
                className="bg-[#f72d0a] py-5 md:px-10 px-2 rounded-md text-gray-200 font-bold text-lg"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <Snackbar
          open={loading}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="warning"
            sx={{
              width: "100%",
              backgroundColor: "orange",
              color: "white",
            }}
          >
            Be patient! It might take a minute.
          </Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={() => setSuccess(false)}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="success"
            sx={{
              width: "100%",
              backgroundColor: "green",
              color: "white",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: "green",
              color: "white",
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <Settings theme={theme} setSelectedTheme={setSelectedTheme} />
    </FullScreen>
  );
}
