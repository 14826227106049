import React, { useState } from "react";
import "./modal.css";
import { BsTrash } from "react-icons/bs";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addHours } from "../../redux/hoursSlice";
import instance from "../../apiInstance";
export default function AddHour({ isOpen, setIsOpen }) {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const submitHour = () => {
    const formData = new FormData();
    formData.append("start_time", start);
    formData.append("end_time", end);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/table-hours`,
      method: "POST",
      data: formData,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setIsOpen(false);
        dispatch(addHours(res.data.table));
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  return (
    <div
      id="myModal"
      className="modal mx-auto "
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <span className="close" onClick={() => setIsOpen(false)}>
        &times;
      </span>
      <div className="modal-content mx-auto mt-32 border-2 rounded p-4">
        <h3 className="text-gray-500 text-xl mt-4 text-center">Add Hours</h3>
        <div className="mt-4">
          <div className="flex flex-col ">
            <label>Starting Hour</label>
            <input
              type="text"
              className="border-2"
              placeholder="ex. 8:00"
              value={start}
              onChange={(e) => setStart(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label>Ending Hour</label>
            <input
              type="text"
              className="border-2"
              placeholder="ex. 9:00"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
            />
          </div>
          <button onClick={() => submitHour()} className="mx-auto block mt-2">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
