import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { IoAddCircleOutline } from 'react-icons/io5';
import Subscription from '../modal/subscription';
import { Alert, Snackbar } from '@mui/material';
import SingleCourse from './singleCourse';
import Classes from '../modal/classes';
export default function Coursescreate({ snackbarRef }) {
	const classes = useSelector((state) => state.classes);
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	return (
		<div className="grid 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4">
			{classes.map((course) => {
				return <SingleCourse course={course} />;
			})}

			<div
				style={{
					position: 'relative',
					height: '300px',
					width: '300px',
				}}
			>
				<IoAddCircleOutline
					size={65}
					className="hamburger block mx-auto absolute"
					style={{
						top: '50%',
						left: '50%',
						transform: 'translateX(-50%,-50%)',
					}}
					onClick={() => setOpen(true)}
				/>
			</div>
			<Classes
				setIsOpen={setOpen}
				isOpen={open}
				success={success}
				setSuccess={setSuccess}
			/>
			<Snackbar
				open={success}
				autoHideDuration={6000}
				onClose={() => setSuccess(false)}
			>
				<Alert
					onClose={() => setSuccess(false)}
					severity="success"
					sx={{
						width: '100%',
						backgroundColor: 'green',
						color: 'white',
					}}
				>
					This is a success message!
				</Alert>
			</Snackbar>
		</div>
	);
}
