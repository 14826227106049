import React, { useState } from "react";
import Navbar from "../components/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sidebar from "../components/sidebar/sidebar";
import Settings from "../components/settings/settings";
import ClassTable from "../components/classes/classTable";
import Coursescreate from "../components/courses/coursescreate";
import UsersList from "../components/users/usersList";
import SidebarPhone from "../components/sidebar/sidebarPhone";
import CreateWod from "../components/wod/createWod";
import PreviousWods from "../components/wod/previousWods";

export default function Wod({ theme, setSelectedTheme }) {
  const handle = useFullScreenHandle();
  const [openSide, setOpenSide] = useState(true);
  const [active, setActive] = useState("wod");
  console.log(active);
  return (
    <FullScreen handle={handle}>
      <Navbar handle={handle} openSide={openSide} setOpenSide={setOpenSide} />
      <div style={{ position: "absolute", top: 50, zIndex: 20 }}>
        <Sidebar openSide={openSide} />
        <SidebarPhone openSide={openSide} setOpenSide={setOpenSide} />
      </div>
      <div className="left-0 absolute">
        <div
          className={`content w-screen overflow-x-hidden h-screen ${
            openSide ? "lg:pl-[260px]" : "lg:pl-[70px]"
          } `}
          style={{ transition: "all 0.2s ease" }}
        >
          <h2
            className="mt-28 pl-4"
            style={{ fontWeight: 700, fontSize: 22 }}
            id="editor"
          >
            Wod
          </h2>
          <div className="mt-4 px-4">
            <div className="flex flex-row justify-center items-center space-x-6 mb-12">
              <div
                className={`${
                  active === "wod"
                    ? "bg-transparent cursor-pointer border-2 p-4 rounded-lg border-green-600"
                    : "bg-gray-900 text-gray-100 p-4 cursor-pointer rounded-lg border-gray-900 border-2"
                } transition-all duration-500`}
                onClick={() => setActive("wod")}
              >
                <h4 className="buttonLabel">WOD</h4>
              </div>
              <div
                className={`${
                  active === "post"
                    ? "bg-transparent cursor-pointer border-2 p-4 rounded-lg border-green-600"
                    : "bg-gray-900 text-gray-100 p-4 cursor-pointer rounded-lg border-gray-900 border-2"
                } transition-all duration-500`}
                onClick={() => setActive("post")}
              >
                <h4 className="buttonLabel">POST</h4>
              </div>
            </div>
            <CreateWod type={active} />
            <PreviousWods type={active} />
          </div>
        </div>
      </div>
      <Settings theme={theme} setSelectedTheme={setSelectedTheme} />
    </FullScreen>
  );
}
