import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { setToLS } from './utils/storage';
import * as themes from './themes/schema.json';
import './index.css';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
const Index = () => {
	setToLS('all-themes', themes.default);
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	);
};
ReactDOM.render(
	<React.StrictMode>
		<Index />
	</React.StrictMode>,
	document.getElementById('root')
);
