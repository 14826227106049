import React, { useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addClassTime, updateClassTime } from "../../redux/classTimeSlice";
import instance from "../../apiInstance";

export default function Square({ start, end, days, name, course }) {
  const [cl, setClass] = useState(name);
  const [flag, setFlage] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const classTimes = useSelector((state) => state.classTimes);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "classes",
    drop: (item) => addImageToBoard(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addImageToBoard = (id) => {
    if (course) {
      setClass(id.name);
      const formdata = new FormData();
      formdata.append("start_time", start);
      formdata.append("end_time", end);
      formdata.append("days", days);
      formdata.append("class_id", id.id);
      instance({
        // url of the api endpoint (can be changed)
        url: `/admin/class-times/${course.id}/update`,
        method: "POST",
        data: formdata,
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(updateClassTime(res.data.class_time));
        })
        .catch((e) => {
          // handle error
          console.log(e);
        });
    } else {
      setClass(id.name);
      const formdata = new FormData();
      formdata.append("start_time", start);
      formdata.append("end_time", end);
      formdata.append("days", days);
      formdata.append("class_id", id.id);
      instance({
        // url of the api endpoint (can be changed)
        url: `/admin/class-times`,
        method: "POST",
        data: formdata,
        headers: { Authorization: "Bearer " + user.token },
      })
        .then((res) => {
          // handle success
          dispatch(addClassTime(res.data.class_time));
        })
        .catch((e) => {
          // handle error
          console.log(e);
        });
    }
  };
  return (
    <>
      <div
        ref={drop}
        className={`h-16 border-2 flex justify-center items-center rounded-xl transition-all duration-500 cursor-pointer`}
        style={{
          backgroundColor: isOver
            ? "black"
            : course
            ? course.classes.length > 0
              ? course.classes[0].color
              : "red"
            : "",
          color: "white",
        }}
        onClick={() => setOpen(true)}
      >
        {course
          ? course.classes.length > 0
            ? course.classes[0].name
            : "Empty"
          : "Empty"}
      </div>
    </>
  );
}
