import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiFillDelete, AiOutlineInfoCircle } from "react-icons/ai";
import axios from "axios";
import { Alert, Popper, Snackbar, TextField } from "@mui/material";
import { addBook, deleteBook } from "../../redux/booksSlice";
import ShowUser from "../modal/showUser";
import UserModal from "./userModal";
import instance from "../../apiInstance";
import DropIn from "../modal/dropIn";
export default function SingleRowPhone({ cl, date }) {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const books = useSelector((state) => state.books);
  const [drop, setDrop] = useState(false);
  const [userActive, setUserActive] = useState("");
  const [inputText, setInputText] = useState("");
  const [success, setSuccess] = useState(false);
  const [del, setDel] = useState(false);
  const [selected, setSelected] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setUserActive(users.filter((us) => us.id == e.target.value));
    setSelected(false);
  };
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }
  const handleSubmit = () => {
    let formdata = new FormData();
    formdata.append("users_id", userActive[0].id);
    formdata.append("date", convertDate(date));
    formdata.append("class_time_id", cl.id);
    instance({
      // url of the api endpoint (can be changed)
      url: "/admin/book",
      data: formdata,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        dispatch(addBook(res.data.book));
        setSelected(true);
        setUserActive("");
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  const deleteBook2 = (book) => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/book/${book.id}`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setDel(true);
        dispatch(deleteBook(book.id));
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  let bookings = books.filter((book) => {
    if (book.class_time_id == cl.id && book.date == convertDate(date))
      return book;
  });
  const handleClick = (e) => {
    setAnchor(e.currentTarget);
    setOpen2(!open2);
  };

  return (
    <>
      <TableRow
        className=""
        sx={{
          "& > *": {
            borderBottom: "unset",
            width: "20%",
          },
        }}
      >
        <TableCell>
          {cl.classes[0].name} : {cl.start_time}-{cl.end_time}
        </TableCell>
        <TableCell align="right">
          <AiOutlineInfoCircle
            className="text-xl inline-block"
            onClick={(e) => handleClick(e)}
          />
          <Popper open={open2} anchorEl={anchor}>
            <Paper>
              <Typography className="text-black">
                Already booked {bookings.length} out of 15
              </Typography>
            </Paper>
          </Popper>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Users
              </Typography>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Full Name</TableCell>
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="w-[1500px]">
                  {bookings.length > 0
                    ? bookings.map((book) => {
                        return (
                          <>
                            <UserModal user={user} book={book} />
                          </>
                        );
                      })
                    : ""}
                  <TableRow>
                    <TableCell>
                      <button onClick={() => setDrop(true)}>Drop IN</button>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          setUserActive(
                            users.filter((us) => us.id == value.id)
                          );
                        }}
                        options={users.map((us) => us)}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="name" />
                        )}
                      />
                    </TableCell>

                    <TableCell align="right">
                      <IoIosAddCircleOutline
                        size={32}
                        className="block text-green-300 cursor-pointer"
                        onClick={() => handleSubmit()}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DropIn
        isOpen={drop}
        date={date}
        cl={cl.id}
        setIsOpen={setDrop}
        setSuccess={setSuccess}
      />
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Book Created Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={del}
        autoHideDuration={6000}
        onClose={() => setDel(false)}
      >
        <Alert
          onClose={() => setDel(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Book Deleted Successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
