import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Collapse,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import instance from "../../apiInstance";
import { addClassTime, updateClassTime } from "../../redux/classTimeSlice";

export default function HeaderPhone({ day }) {
  const [open, setOpen] = useState(false);
  const classTimes = useSelector((state) => state.classTimes);
  const classes = useSelector((state) => state.classes);
  const user = useSelector((state) => state.user);
  const data = useSelector((state) => state.hours);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  let filteredData = classTimes.filter((el) => {
    //if no input the return the original
    if (el.days === day) {
      return el;
    }
  });

  let newData = filteredData.map((filt) =>
    data.filter((d) => {
      if (d.start !== filt.start_time && d.end !== filt.end_time) {
        return d;
      }
    })
  );
  const updateClass = (cl, start, end, course) => {
    const formdata = new FormData();
    formdata.append("start_time", start);
    formdata.append("end_time", end);
    formdata.append("days", day);
    formdata.append("class_id", cl.id);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/class-times/${course.id}/update`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(updateClassTime(res.data.class_time));
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  const addClass = (cl, start, end) => {
    const formdata = new FormData();
    formdata.append("start_time", start);
    formdata.append("end_time", end);
    formdata.append("days", day);
    formdata.append("class_id", cl.id);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/class-times`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(addClassTime(res.data.class_time));
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  return (
    <>
      <TableHead>
        <TableRow className="header">
          <TableCell>
            <Typography className="text-gray-200">{day}</Typography>
          </TableCell>
          <TableCell align="right">
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? (
                <KeyboardArrowUpOutlined />
              ) : (
                <KeyboardArrowDownOutlined />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  className=""
                >
                  <h4>Classes</h4>
                </Typography>
                <Table size="medium" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography className="text-gray-100">Time</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography className="text-gray-100">Class</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="w-[1500px]">
                    {data.map((time) => {
                      let flag = 0;
                      return (
                        <>
                          {filteredData.map((course) => {
                            if (
                              time.start_time === course.start_time &&
                              time.end_time === course.end_time
                            ) {
                              flag = 1;
                              return (
                                <TableRow>
                                  <TableCell>
                                    {course.start_time}-{course.end_time}
                                  </TableCell>
                                  <TableCell>
                                    <Autocomplete
                                      disablePortal
                                      id="combo-box-demo"
                                      onChange={(e, value) => {
                                        updateClass(
                                          value,
                                          time.start_time,
                                          time.end_time,
                                          course
                                        );
                                      }}
                                      options={classes.map((us) => us)}
                                      sx={{ width: 185 }}
                                      getOptionLabel={(option) => option.name}
                                      isOptionEqualToValue={(option, value) =>
                                        option === value
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder={course.classes[0].name}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}

                          {flag === 0 ? (
                            <TableRow>
                              <TableCell>
                                {time.start_time}-{time.end_time}
                              </TableCell>
                              <TableCell>
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  onChange={(e, value) => {
                                    addClass(
                                      value,
                                      time.start_time,
                                      time.end_time
                                    );
                                  }}
                                  options={classes.map((us) => us)}
                                  sx={{ width: 185 }}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    option === value
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Empty"
                                    />
                                  )}
                                />
                              </TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableHead>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Class updated successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
