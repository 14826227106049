import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { useSelector } from "react-redux";
import User from "../modal/user";
import UserSingle from "./userSingle";
import { styled } from "@mui/system";
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";
import UserSinglePhone from "./userSinglePhone";
const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

export default function UserListPhone({ filteredData }) {
  const [display, setDisplay] = useState(0);
  const users = useSelector((state) => state.users);
  const [inputText, setInputText] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleNext = () => {
    if (display + 1 >= users.length) {
      setDisplay(display);
    } else {
      setDisplay(display + 1);
    }
  };
  const handlePrevious = () => {
    if (display - 1 === -1) {
      setDisplay(0);
    } else {
      setDisplay(display - 1);
    }
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;
  return (
    <div className="overflow-x-auto relative shadow-md rounded-2xl mt-4 ">
      {filteredData.map((day, index) => {
        return index === display ? (
          <>
            <table className="text-sm text-left   rounded-xl lg:hidden block w-full ">
              <thead className="text-xs uppercase  tableHead w-full block border-b-2">
                <tr className="w-full block">
                  <th scope="col" className="py-3 px-6 text-center w-full">
                    Fullname
                  </th>
                </tr>
              </thead>
              <tbody className="w-full block">
                {(rowsPerPage > 0
                  ? filteredData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredData
                ).map((row) => (
                  <UserSinglePhone user={row} />
                ))}

                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={5} />
                  </tr>
                )}
              </tbody>
              <tfoot className="w-full block">
                <tr className="w-full block">
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      10,
                      15,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    className="w-screen"
                    colSpan={6}
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    componentsProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </tr>
              </tfoot>
            </table>
          </>
        ) : (
          ""
        );
      })}{" "}
      <User
        isOpen={open}
        setIsOpen={setOpen}
        success={success}
        setSuccess={setSuccess}
      />
    </div>
  );
}
