import React from 'react';
import './modal.css';
import { BsTrash } from 'react-icons/bs';
export default function Students({ isOpen, setIsOpen, lesson }) {
	return (
		<div
			id="myModal"
			className="modal mx-auto "
			style={{
				display: isOpen ? 'block' : 'none',
			}}
		>
			<span className="close" onClick={() => setIsOpen(false)}>
				&times;
			</span>
			<div className="modal-content mx-auto mt-32 border-2 rounded p-4">
				<h3 className="text-gray-500 text-3xl mt-4 text-center">
					Students for {lesson.time + ' ' + lesson.title}{' '}
				</h3>
				<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-xl bg-gray-400">
					<thead className="text-xs text-gray-700 uppercase bg-gray-400">
						<tr>
							<th scope="col" className="py-3 px-6 text-center">
								Student
							</th>
							<th scope="col" className="py-3 px-6 text-center">
								Actions
							</th>
						</tr>
					</thead>
					<tbody className="tableRow">
						{lesson.students.map((student) => {
							return (
								<tr className="tableRow text-end h-8 border-b-2">
									<td className="text-center">
										{student.name}
									</td>
									<td className="text-center">
										<BsTrash
											color="#f50008"
											className="mx-auto"
											size={20}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}
