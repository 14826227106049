import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import usersReducer from "./usersSlice";
import classesReducer from "./classesSlice";
import subtypesReducer from "./subtypeSlice";
import classTimeReducer from "./classTimeSlice";
import booksReducer from "./booksSlice";
import settingReducer from "./settingSlice";
import wodReducer from "./wodSlice";
import postReducer from "./postSlice";
import hoursReducer from "./hoursSlice";
import notificationsReducer from './notificationSlice'
import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware, combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
const persistConfig = {
  key: "root2",
  storage,
};
const combReduces = combineReducers({
  user: userReducer,
  users: usersReducer,
  classes: classesReducer,
  subtypes: subtypesReducer,
  classTimes: classTimeReducer,
  books: booksReducer,
  setting: settingReducer,
  wod: wodReducer,
  post: postReducer,
  hours: hoursReducer,
  notifications: notificationsReducer,
});
const persistedReducer = persistReducer(persistConfig, combReduces);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
