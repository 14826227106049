import React, { useState, useEffect } from "react";
import { BsPeopleFill } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";
import Students from "../modal/students";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { deleteUser, updateUser } from "../../redux/usersSlice";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Alert, Snackbar } from "@mui/material";
import instance from "../../apiInstance";
export default function UserSinglePhone({ user }) {
  const [success, setSuccess] = useState(false);
  const subtypes = useSelector((state) => state.subtypes);
  const token = useSelector((state) => state.user.token);
  const admin = useSelector((state) => state.user);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [roles, setRoles] = useState(user.roles);
  const [sub, setSub] = useState(user.subscription);
  const [type, setType] = useState(user.user_sub);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setRoles(user.roles);
    setSub(user.subscription);
    if (user.user_sub) {
      setType(user.user_sub.subscription_type);
    }
  }, [user]);
  const handleUpdate = () => {
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("roles", roles);
    formdata.append("subscription", sub);
    formdata.append("user_sub", type);
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/user/${user.id}/update`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + admin.token },
    })
      .then((res) => {
        // handle success
        dispatch(updateUser(res.data.user));
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
      });
  };
  const handleDelete = () => {
    instance({
      url: `/admin/user/${user.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => {
        // handle success
        dispatch(deleteUser(user.id));
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        setMessage(error.response.data.message);
        setError(true);
      });
  };
  return (
    <tr className="tableRow text-end w-full block border-b-2">
      <td
        className="text-start px-3 py-6  grid grid-cols-2 justify-items-center"
        onClick={() => setShow(!show)}
      >
        <h3>{name}</h3>
        <AiOutlineArrowDown
          className={`text-2xl text-white border-2 rounded-xl cursor-pointer ${
            show ? "rotate-180" : "rotate-0"
          } transition-all duration-500 ease-in-out`}
        />
      </td>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          {user.name} updated successfully!
        </Alert>
      </Snackbar>
      {/* <Students isOpen={open} setIsOpen={setOpen} lesson={lesson} /> */}
      <div
        className={`grid grid-row-3 gap-4 px-4 bg-gray-300 items-center text-center ${
          show ? "max-h-full py-2 border-2" : "max-h-0 py-0"
        }   transition-all duration-300 ease-in-out h-full`}
      >
        <div
          className={`${
            show ? "opacity-1" : "opacity-0 hidden"
          } transition-all duration-500 ease-in-out text-gray-700`}
        >
          <h4>FullName</h4>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="p-2"
          />
        </div>
        <div
          className={`${
            show ? "opacity-1" : "opacity-0 hidden"
          } transition-all duration-500 ease-in-out text-gray-700`}
        >
          <h4>Email</h4>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2"
          />
        </div>
        <div
          className={`${
            show ? "opacity-1" : "opacity-0 hidden"
          } transition-all duration-500 ease-in-out text-gray-700`}
        >
          <h4>Role</h4>
          <select
            className="p-2"
            onChange={(e) => setRoles(e.target.value)}
            value={roles}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
            <option value="trial">Trial</option>
          </select>
        </div>
        <div
          className={`${
            show ? "opacity-1" : "opacity-0 hidden"
          } transition-all duration-500 ease-in-out text-gray-700 `}
        >
          <h4>Subscription Duration</h4>
          <input
            value={sub}
            onChange={(e) => setSub(e.target.value)}
            className="p-2"
          />
        </div>
        <div
          className={`${
            show ? "opacity-1" : "opacity-0 hidden"
          } transition-all duration-500 ease-in-out text-gray-700`}
        >
          <h4>Subscription Type</h4>
          <select onChange={(e) => setType(e.target.value)} className="p-2">
            <option value={null}></option>
            {subtypes.map((sub) => {
              if (user.user_sub) {
                if (user.user_sub.sub_type) {
                  if (sub.id === user.user_sub.sub_type.id)
                    return (
                      <option value={sub.id} selected="selected">
                        {sub.subscription_name}
                      </option>
                    );
                }
              }
              return <option value={sub.id}>{sub.subscription_name}</option>;
            })}
          </select>
        </div>
        <div
          className={`${
            show ? "opacity-1" : "opacity-0 hidden"
          } transition-all duration-500 ease-in-out text-gray-700`}
        >
          <button className="bg-green-400" onClick={handleUpdate}>
            Save
          </button>
          <button className="bg-red-400 mx-4" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          {user.name} successful action!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </tr>
  );
}
