import { createSlice } from "@reduxjs/toolkit";

export const settingSlice = createSlice({
  name: "setting",
  initialState: [],
  reducers: {
    getSetting: (state, action) => {
      return action.payload;
    },
    updateSetting: (state, action) => {
      return state.map((item, index) => {
        return action.payload.id === item.id
          ? {
              ...action.payload,
            }
          : item;
      });
    },
    addSetting: (state, action) => {
      return [...state, action.payload];
    },

    logoutSetting: (state, action) => {
      return [];
    },
  },
});

export const { getSetting, logoutSetting, updateSetting, addSetting } =
  settingSlice.actions;
export default settingSlice.reducer;
