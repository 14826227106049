import React from "react";
import { useDrag } from "react-dnd";
export default function Cl({ name, id, color }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "classes",
    item: { id: id, name: name, color: color },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`h-16 border-2 flex justify-center items-center rounded-xl  transition-all duration-500  cursor-grab`}
      style={{ backgroundColor: isDragging ? "gray" : color }}
    >
      <h4>{name}</h4>
    </div>
  );
}
