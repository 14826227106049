import React from "react";
import "./modal.css";
import { BsTrash } from "react-icons/bs";
export default function Danger({ isOpen, setIsOpen, text, action }) {
  return (
    <div
      id="myModal"
      className="modal mx-auto "
      style={{
        display: isOpen ? "block" : "none",
      }}
    >
      <span className="close" onClick={() => setIsOpen(false)}>
        &times;
      </span>
      <div className="modal-content mx-auto mt-32 border-2 rounded p-4">
        <h3 className="text-gray-500 text-xl mt-4 text-center">{text}</h3>
        <div className="mt-4 flex justify-center">
          <button
            className="bg-red-600 py-2 px-4 mr-4"
            onClick={(e) => action(e)}
          >
            Yes
          </button>
          <button
            className="bg-gray-400 py-2 px-4"
            onClick={() => setIsOpen(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}
