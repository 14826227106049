import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";

import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import WebFont from "webfontloader";
import { useTheme } from "../../themes/useTheme";
import {
  AiOutlineDashboard,
  AiOutlineDatabase,
  AiOutlineEdit,
  AiOutlineFileText,
  AiOutlineSchedule,
  AiOutlineUser,
  AiOutlineDollarCircle,
  AiOutlineSetting,
  AiOutlineMail,
  AiOutlinePlusCircle,
  AiOutlineNotification,
} from "react-icons/ai";

import { IoFitnessOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updatePhoto } from "../../redux/userSlice";
import instance from "../../apiInstance";
export default function Sidebar({ openSide }) {
  const { theme, themeLoaded, getFonts } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedTheme(theme);
  }, [themeLoaded, theme]);

  // 4: Load all the fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: getFonts(),
      },
    });
  });
  useEffect(() => {
    setOpen(openSide);
  }, [openSide]);
  const updatePhoto2 = (e) => {
    const formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/upload-avatar`,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
      data: formData,
    })
      .then((res) => {
        // handle success
        dispatch(updatePhoto(res.data.user.avatar));
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  return (
    <div
      style={{
        overflowX: "hidden",
        transition: "all 0.2s ease",
      }}
      className={`fixed top-[30px] h-screen lg:block hidden ${
        open ? "lg:[240px]" : "lg:w-[60px] w-[0px]"
      } navbar`}
    >
      <Toolbar />
      <Box sx={{ top: 50 }}>
        <List
          style={{
            opacity: open ? "1" : "0",
            width: open ? 240 : 0,
            transition: "all ease 0.2s",
          }}
        >
          <ListItem>
            <img
              src={
                user.avatar === "default.jpg"
                  ? "./assets/LogoBarbox2.png"
                  : user.avatar
              }
              className={`block rounded-full h-20 w-20 mx-auto object-cover cursor-pointer${
                hover ? "bg-gray-700/90" : "bg-transparent"
              } transition-all duration-300`}
              alt="profile"
            />
            <div class="image-upload" for="file-input">
              <form>
                <label
                  for="file-input"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <AiOutlinePlusCircle
                    className={`absolute ${
                      hover ? "opacity-1" : "opacity-0"
                    } transition-all duration-200 text-gray-100 z-0 cursor-pointer rounded-full`}
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      background: "rgba(0,0,0,1)",
                    }}
                    size={32}
                  />
                </label>
                <input
                  id="file-input"
                  type="file"
                  className="hidden"
                  onChange={(e) => updatePhoto2(e)}
                />
              </form>
            </div>
          </ListItem>
          <ListItem className="text-center" style={{ paddingTop: 0 }}>
            <h3 className="mx-auto block ">{user.name}</h3>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => navigate("/")}>
            <ListItemIcon>
              <AiOutlineDashboard size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Dashboard</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/users")}>
            <ListItemIcon>
              <AiOutlineUser size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Users</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/books")}>
            <ListItemIcon>
              <AiOutlineDatabase size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Books</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/subtypes")}>
            <ListItemIcon>
              <AiOutlineDollarCircle size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Subscription</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          {/* <ListItem button onClick={() => navigate('/classes')}>
						<ListItemIcon>
							<AiOutlineEdit size={25} />
						</ListItemIcon>
						{open ? (
							<ListItemText>
								<h2 className="">Wod</h2>
							</ListItemText>
						) : (
							''
						)}
					</ListItem> */}
          <ListItem button onClick={() => navigate("/schedule")}>
            <ListItemIcon>
              <AiOutlineSchedule size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Schedule</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/courses")}>
            <ListItemIcon>
              <IoFitnessOutline size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Classes</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/wod")}>
            <ListItemIcon>
              <AiOutlineFileText size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Wod</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/email")}>
            <ListItemIcon>
              <AiOutlineMail size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Email</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
          <ListItem button onClick={() => navigate("/notification")}>
            <ListItemIcon>
              <AiOutlineNotification size={25} className="icon" />
            </ListItemIcon>
            {open ? (
              <ListItemText>
                <h2 className="">Notifications</h2>
              </ListItemText>
            ) : (
              ""
            )}
          </ListItem>
        </List>
        <ListItem button onClick={() => navigate("/configuration")}>
          <ListItemIcon>
            <AiOutlineSetting size={25} className="icon" />
          </ListItemIcon>
          {open ? (
            <ListItemText>
              <h2 className="">Configuration</h2>
            </ListItemText>
          ) : (
            ""
          )}
        </ListItem>
        <Divider />
        {/* <List>
					{['All mail', 'Trash', 'Spam'].map((text, index) => (
						<ListItem button key={text}>
							<ListItemIcon>
								{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
							</ListItemIcon>
							{open ? <ListItemText primary={text} /> : ''}
						</ListItem>
					))}
				</List> */}
      </Box>
    </div>
  );
}
