import React, { useState } from "react";
import Navbar from "../components/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sidebar from "../components/sidebar/sidebar";
import Settings from "../components/settings/settings";
import ClassTable from "../components/classes/classTable";
import Schedulemodify from "../components/schedule/schedulemodify";
import SidebarPhone from "../components/sidebar/sidebarPhone";
import SchedulePhone from "../components/schedule/schedulePhone";

export default function Schedule({ theme, setSelectedTheme }) {
  const handle = useFullScreenHandle();
  const [openSide, setOpenSide] = useState(true);

  return (
    <FullScreen handle={handle}>
      <Navbar handle={handle} openSide={openSide} setOpenSide={setOpenSide} />
      <div style={{ position: "absolute", top: 50, zIndex: 20 }}>
        <Sidebar openSide={openSide} />
        <SidebarPhone openSide={openSide} setOpenSide={setOpenSide} />
      </div>
      <div className="left-0 absolute">
        <div
          className={`content w-screen overflow-x-hidden h-screen ${
            openSide ? "lg:pl-[260px]" : "lg:pl-[70px]"
          } `}
          style={{ transition: "all 0.2s ease" }}
        >
          <h2 className="mt-28 pl-4" style={{ fontWeight: 700, fontSize: 22 }}>
            Classes
          </h2>
          <h2 className="mt-4 pl-4" style={{ fontWeight: 700, fontSize: 22 }}>
            Current Schedule
          </h2>
          <div className="mt-4 px-4 lg:block hidden">
            <Schedulemodify />
          </div>
          <div className="mt-4 px-4 lg:hidden block">
            <SchedulePhone />
          </div>
        </div>
      </div>
      <Settings theme={theme} setSelectedTheme={setSelectedTheme} />
    </FullScreen>
  );
}
