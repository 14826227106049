import React, { useState } from 'react'
import Singlerow from './singlerow'
import {MdNavigateNext,MdNavigateBefore} from 'react-icons/md'
export default function ClassTable() {
    const [display,setDisplay] = useState(0)
    const classes = [
        {
            date: '19/7/2022',
            day: 'Monday',
            lessons: [
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 12,
                    limit: 15,
                    students: [
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        },
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        },
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        },
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }
                    ]
                },
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                },
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                },
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                },
            ]
        }, {
            date: '20/7/2022',
            day: 'Tuesday',
            lessons: [
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        },
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        },
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        },
                        {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }, {
                            name: 'Elias Verginis',
                            email: 'eliasver99@gmail.com'
                        }
                    ]
                },
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                },
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                },
                {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                }, {
                    time: '8:00-9:00',
                    title: 'CROSS LEVEL 1',
                    enrolled: 10,
                    limit: 15,
                    students: [{}]
                },
            ]
        },
    ]
    const handleNext=()=>{
        if(display+1>=classes.length){
            setDisplay(display)
        }
        else{
            setDisplay(display+1);
        }
    }
    const handlePrevious=()=>{
        if(display-1 === -1){
            setDisplay(0)
        }
        else{
            setDisplay(display-1)
        }
    }
    return (
        <div className='overflow-x-auto relative shadow-md rounded-2xl'>
            {
            classes.map((day,index) => {
               
                return (
                    index === display?<>
                    <div className='mx-auto items-center justify-center flex'>
                    <MdNavigateBefore className='hamburger inline-block mr-4' size={35} onClick={()=>handlePrevious()}/>
                    <h4 className='text-center inline-block text-xl'>{day.day+' '+day.date}</h4>
                    <MdNavigateNext className='hamburger inline-block ml-4' size={35} onClick={()=>handleNext()}/>
                    </div>
                    <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-xl'>
                        <thead className='text-xs text-gray-700 uppercase bg-gray-50 tableHead'>
                            <tr>
                                <th scope="col" className='py-3 px-6 text-center'>
                                    Time
                                </th>
                                <th scope="col" className='py-3 px-6 text-center'>
                                    Title
                                </th>
                                <th scope="col" className='py-3 px-6 text-center'>
                                    Students
                                </th>
                                <th scope="col" className='py-3 px-6 text-center'>
                                    Limit
                                </th>
                                <th scope="col" className='py-3 px-6 text-center'>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className='tableRow'>
                            {
                            day.lessons.map((lesson) => {
                                return <Singlerow lesson={lesson}/>
                            })
                        } </tbody>
                    </table>
                    </>:''
                )
            })
        } </div>
    )
}
