import React, { useState, useEffect } from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { addDays, format, getDate, parseISO, startOfWeek } from "date-fns";
import { useSelector } from "react-redux";
import {
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import HeaderPhone from "./headerPhone";
export default function SchedulePhone() {
  const [active, setActive] = useState("Monday");
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [today, setToday] = useState(new Date(Date.now()));
  const classTimes = useSelector((state) => state.classTimes);
  var days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return (
    <div className="overflow-x-auto relative shadow-md rounded-2xl">
      <Table aria-label="simple table" className="  w-screen">
        {days.map((day) => {
          return <HeaderPhone day={day} />;
        })}
      </Table>
    </div>
  );
}
