import React, { useState } from "react";
import Navbar from "../components/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Sidebar from "../components/sidebar/sidebar";
import Settings from "../components/settings/settings";
import Cards from "../components/cards/cards";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import instance from "../apiInstance";
import { Alert, Snackbar } from "@mui/material";
import { updateData } from "../redux/userSlice";
import { useDispatch } from "react-redux";
export default function ProfileSettings({ theme, setSelectedTheme }) {
  const handle = useFullScreenHandle();
  const user = useSelector((state) => state.user);
  const [openSide, setOpenSide] = useState(true);
  const [username, setUsername] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [conf, setPass] = useState("");
  const [np, setNp] = useState("");
  const [cp, setCp] = useState("");
  const [p, setP] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = () => {
    console.log("hello");
  };
  const submitForm = () => {
    const formdata = new FormData();
    formdata.append("name", username);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("password_confirmation", conf);
    formdata.append("avatar", user.avatar);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/update-user-creds?`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        dispatch(updateData(res.data.user));
        setMessage(res.data.message);
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        setMessage(e.response.data.message);
        setError(true);
      });
  };
  const changePassword = () => {
    const formdata = new FormData();
    formdata.append("old_password", p);
    formdata.append("password", np);
    formdata.append("password_confirmation", cp);
    instance({
      // url of the api endpoint (can be changed)
      url: `/web/user/settings/change-password`,
      method: "POST",
      data: formdata,
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setMessage(res.data.message);
        setSuccess(true);
      })
      .catch((e) => {
        // handle error
        setMessage(e.response.data.message);
        setError(true);
      });
  };
  return (
    <FullScreen handle={handle}>
      <Navbar handle={handle} openSide={openSide} setOpenSide={setOpenSide} />
      <div style={{ position: "absolute", top: 50, zIndex: 20 }}>
        <Sidebar openSide={openSide} />
      </div>
      <div className="left-0 absolute">
        <div
          className={`content w-screen overflow-x-hidden h-screen ${
            openSide ? "lg:pl-[260px]" : "lg:pl-[70px]"
          } `}
          style={{ transition: "all 0.2s ease" }}
        >
          <h2 className="mt-28 pl-4" style={{ fontWeight: 700, fontSize: 22 }}>
            Settings
          </h2>

          <div className="flex flex-col w-full px-12 mt-8 space-y-12 ">
            <div className="flex flex-row justify-center gap-12 flex-wrap text-black">
              <div className="lg:w-1/3 w-full flex-col space-y-2">
                <h4 className="text-gray-800">Username</h4>
                <input
                  type="text"
                  value={username}
                  className="w-full border-2 rounded-xl px-2"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="lg:w-1/3 w-full flex-col space-y-2">
                <h4 className="text-gray-800">Email</h4>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border-2 rounded-xl px-2"
                />
              </div>
              <div className="lg:w-1/3 w-full flex-col space-y-2">
                <h4 className="text-gray-800">Password</h4>
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full border-2 rounded-xl px-2"
                />
              </div>
              <div className="lg:w-1/3 w-full flex-col space-y-2">
                <h4 className="text-gray-800">Password Confirmation</h4>
                <input
                  type="password"
                  onChange={(e) => setPass(e.target.value)}
                  className="w-full border-2 rounded-xl px-2"
                />
              </div>
            </div>
            <div className="flex flex-row">
              <button
                onClick={() => submitForm()}
                className="border-2 rounded-xl p-4 block mx-auto border-red-600 text-white-600 hover:bg-red-600 hover:text-gray-200 transition-all duration-150"
              >
                Update Profile
              </button>
            </div>

            <div>
              <h3 className="text-center tracking-widest text-xl">
                Change Password
              </h3>
              <div className="flex flex-col justify-center items-center ">
                <div className="lg:w-1/3 w-full flex-col space-y-2">
                  <h4 className="text-gray-800 text-center">New Password</h4>
                  <input
                    type="password"
                    onChange={(e) => setNp(e.target.value)}
                    className="w-full border-2 rounded-xl px-2"
                  />
                </div>
                <div className="lg:w-1/3 w-full flex-col space-y-2">
                  <h4 className="text-gray-800 text-center">
                    Password Confirmation
                  </h4>
                  <input
                    type="password"
                    onChange={(e) => setCp(e.target.value)}
                    className="w-full border-2 rounded-xl px-2"
                  />
                </div>
                <div className="lg:w-1/3 w-full flex-col space-y-2">
                  <h4 className="text-gray-800 text-center">Old Password</h4>
                  <input
                    type="password"
                    onChange={(e) => setP(e.target.value)}
                    className="w-full border-2 rounded-xl px-2"
                  />
                </div>
                <div className="flex flex-row">
                  <button
                    onClick={() => changePassword()}
                    className="border-2 mt-4 rounded-xl p-4 block mx-auto border-red-600 text-white-600 hover:bg-red-600 hover:text-gray-200 transition-all duration-150"
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Settings theme={theme} setSelectedTheme={setSelectedTheme} />
    </FullScreen>
  );
}
