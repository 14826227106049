import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { Alert, Snackbar, TextField } from "@mui/material";
import { addBook, deleteBook } from "../../redux/booksSlice";
import instance from "../../apiInstance";
import DropIn from "../modal/dropIn";
export default function Singlerow({ cl, date }) {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const books = useSelector((state) => state.books);
  const settings = useSelector((state) => state.setting);
  const [userActive, setUserActive] = useState("");
  const [inputText, setInputText] = useState("");
  const [success, setSuccess] = useState(false);
  const [del, setDel] = useState(false);
  const [drop, setDrop] = useState(false);
  const [selected, setSelected] = useState(true);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setUserActive(users.filter((us) => us.id == e.target.value));
    setSelected(false);
  };
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }
  const handleSubmit = () => {
    let formdata = new FormData();
    formdata.append("users_id", userActive[0].id);
    formdata.append("date", convertDate(date));
    formdata.append("class_time_id", cl.id);
    instance({
      // url of the api endpoint (can be changed)
      url: "/admin/book",
      data: formdata,
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setSuccess(true);
        dispatch(addBook(res.data.book));
        setSelected(true);
        setUserActive("");
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };
  const deleteBook2 = (book) => {
    instance({
      // url of the api endpoint (can be changed)
      url: `/admin/book/${book.id}/delete`,
      method: "DELETE",
      headers: { Authorization: "Bearer " + user.token },
    })
      .then((res) => {
        // handle success
        setDel(true);
        dispatch(deleteBook(book.id));
      })
      .catch((e) => {
        // handle error
        console.log(e);
      });
  };

  let bookings = books.filter((book) => {
    if (book.class_time_id == cl.id && book.date == convertDate(date))
      return book;
  });

  return (
    <>
      <TableRow
        className={`${
          bookings.length === settings[0].class_limit ? "bg-red-400" : ""
        }`}
        sx={{
          "& > *": {
            borderBottom: "unset",
            width: "20%",
            background:
              bookings.length === settings[0].class_limit ? "#eb4034" : "",
          },
        }}
      >
        <TableCell>{cl.start_time}</TableCell>
        <TableCell>{cl.end_time}</TableCell>
        <TableCell>{cl.classes[0].name}</TableCell>
        <TableCell>{bookings.length}</TableCell>
        <TableCell>{settings[0].class_limit}</TableCell>
        <TableCell>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Users
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className="text-white">
                    <TableCell className="text-white">Full Name</TableCell>
                    <TableCell className="text-white">Email</TableCell>
                    <TableCell align="right" className="text-white">
                      Subscription
                    </TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings.length > 0
                    ? bookings.map((book) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {book.user.name}
                              </TableCell>
                              <TableCell component="th">
                                {book.user.email}
                              </TableCell>
                              <TableCell align="right" component="th">
                                {book.user.subscription}
                              </TableCell>
                              <TableCell>
                                <AiFillDelete
                                  className="block mx-auto text-red-400 cursor-pointer"
                                  size={32}
                                  onClick={() => deleteBook2(book)}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    : ""}
                  <TableRow>
                    <TableCell>
                      <button onClick={() => setDrop(true)}>Drop IN</button>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        onChange={(e, value) => {
                          setUserActive(
                            users.filter((us) => us.id == value.id)
                          );
                        }}
                        options={users.map((us) => us)}
                        sx={{ width: 300 }}
                        getOptionLabel={(option) =>
                          option.name + " " + option.email
                        }
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="name" />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      {userActive[0] ? userActive[0].email : ""}
                    </TableCell>
                    <TableCell align="right">
                      {userActive[0] ? userActive[0].subscription : ""}
                    </TableCell>
                    <TableCell align="right">
                      <IoIosAddCircleOutline
                        size={32}
                        className="block mx-auto text-green-300 cursor-pointer"
                        onClick={() => handleSubmit()}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DropIn
        isOpen={drop}
        date={date}
        cl={cl.id}
        setIsOpen={setDrop}
        setSuccess={setSuccess}
      />
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Book Created Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={del}
        autoHideDuration={6000}
        onClose={() => setDel(false)}
      >
        <Alert
          onClose={() => setDel(false)}
          severity="success"
          sx={{
            width: "100%",
            backgroundColor: "green",
            color: "white",
          }}
        >
          Book Deleted Successfully!
        </Alert>
      </Snackbar>
    </>
  );
}
