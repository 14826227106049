import React from 'react';
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import { useSelector } from 'react-redux';
const data = [
	{
		name: 'Page A',
		uv: 4000,

		amt: 2400,
	},
	{
		name: 'Page B',
		uv: 3000,
		amt: 2210,
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		amt: 2290,
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		amt: 2000,
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		amt: 2181,
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		amt: 2500,
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
];
export default function SubtypesGraph() {
	const subtypes = useSelector((state) => state.subtypes);
	const users = useSelector((state) => state.users);
	var userSub = [];
	const fillData = () => {
		let objectTest = {};
		for (let i = 0; i < subtypes.length; i++) {
			const userPerSub = users.filter((user) => {
				if (
					user.user_sub &&
					user.user_sub.subscription_type === subtypes[i].id
				) {
					return user;
				}
			});
			objectTest['id'] = subtypes[i].id;
			objectTest['name'] = subtypes[i].subscription_name;
			objectTest['number'] = userPerSub.length;
			userSub.push(objectTest);
			objectTest = {};
		}
	};
	fillData();
	return (
		<div className="w-full h-[400px]">
			<h3 className="text-center mb-4 text-lg font-bold">
				Users Per Subscription Type
			</h3>
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={400}
					data={userSub}
					className=" p-0 mx-auto"
					
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis domain={[0, users.length]} />
					<Tooltip cursor={{ fill: 'transparent' }} />
					<Bar dataKey="number" fill="#8884d8" />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
}
